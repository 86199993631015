import { useRudderstackID } from './useRudderstackID';

declare global {
  interface Window {
    rudderanalytics: any;
  }
}

export const useRudderStack = () => {
  const anonymousId = useRudderstackID();

  return {
    track: (event: string, properties: Object) => {
      if (typeof window !== 'undefined' && window.rudderanalytics) {
        window.rudderanalytics.track(event, properties);
      }
    },
    anonymousId,
  };
};
