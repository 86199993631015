import { MouseEvent, ReactNode } from 'react';
import { BaseButton } from './base';
import { TButtonSize, TButtonColor } from './button.type';

export type Props = {
  text: string;
  color?: TButtonColor;
  type?: 'button' | 'submit' | 'reset';
  size?: TButtonSize;
  icon?: string | ReactNode;
  clickHandler: (e: MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  disabledTooltip?: string;
  isLoading?: boolean;
  className?: string;
  textClassName?: string;
  buttonClassName?: string;
};

function Button({
  text,
  color,
  type = 'button',
  size,
  icon,
  clickHandler,
  isDisabled = false,
  disabledTooltip,
  isLoading = false,
  className,
  textClassName,
  buttonClassName,
}: Props) {
  return (
    <button
      className={buttonClassName}
      type={type}
      onClick={!isDisabled ? clickHandler : () => {}}
      title={isDisabled ? disabledTooltip : undefined}
    >
      <BaseButton
        text={text}
        color={color}
        size={size}
        icon={icon}
        isDisabled={isDisabled}
        isLoading={isLoading}
        className={className}
        textClassName={textClassName}
      />
    </button>
  );
}

export { Button };
