import { useFlags } from 'flagsmith/react';
import { AB_TEST_FEATURE } from '../../data/flags';

const useExperimentalFeature = (
  feature: (typeof AB_TEST_FEATURE)[keyof typeof AB_TEST_FEATURE],
  experimentalValue: string
): boolean => {
  const flags = useFlags([feature]);
  const flag = flags[feature];

  const isExperimental = Boolean(
    flags && flag && flag.enabled && flag.value === experimentalValue
  );

  return isExperimental;
};

const useExperimentalFeatureVariant = (
  feature: (typeof AB_TEST_FEATURE)[keyof typeof AB_TEST_FEATURE]
): {
  isEnabled: boolean;
  variant: string | number | boolean | null | undefined;
} => {
  const flags = useFlags([feature]);
  const flag = flags[feature];

  const isEnabled = Boolean(flags && flag && flag.enabled);

  return { isEnabled, variant: flag.value };
};

export { useExperimentalFeature, useExperimentalFeatureVariant };
