import React, { useState } from 'react';
import { AppLink } from '@/Components/link/link';
import classes from './gworld-menu.module.css';
import { useDevice } from '@/Lib/hooks/useDevice';
import { GImage } from '@/Components/g-image';

const gworlDomainName =
  process?.env?.NEXT_PUBLIC_GWORLD_DOMAIN_NAME ||
  'gworld.globalworkandtravel.com';

type Props = {
  className?: string;
};

const GWorldButton = ({ className }: Props) => {
  const [isActive, setIsActive] = useState(false);
  const { isMD } = useDevice();

  return (
    <div className={`flex md:relative ${className} ${classes.gworldMenu}`}>
      <div
        className={`cursor-pointer lg:px-0 lg:pl-5 lg:py-4.25 flex justify-center items-center h-full 
        ${classes.gworldDropdown}`}
        onClick={() => {
          setIsActive(!isActive);
        }}
        onMouseEnter={() => isMD && setIsActive(true)}
        onMouseLeave={() => isMD && setIsActive(false)}
      >
        <i className="icon-profile pr--0.5 text-xl lg:text-base" />
        <i className="hidden md:block icon-down text-3xs ml-1.5" />
        <div
          className={`flex flex-col absolute top-[99%] right-0 shadow-lg justify-center items-start md:items-center -z-1 bg-white min-w-full md:min-w-min ${
            classes.gworldDropdownMenu
          } ${isActive ? classes.active : ''}`}
        >
          <div className="flex flex-1 flex-col ml-4 md:ml-2 md:mr-6 gap-4 my-4">
            <a
              href={`https://${gworlDomainName}`}
              className="flex items-center gap-2"
              target="_blank"
            >
              <div className="w-10 h-10">
                <GImage
                  path="website/icons/page/gworld.svg"
                  alt="gworld icon"
                  disableLoadAnimation
                />
              </div>
              <div className="text-base font-medium whitespace-nowrap">
                Manage my trip
              </div>
            </a>
            <AppLink href="/gworld" className="flex items-center gap-2">
              <i className="icon-question text-lg ml-3 mr-2.5" />
              <div className="text-base font-medium whitespace-nowrap">
                What is gWorld?
              </div>
            </AppLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export { GWorldButton };
