const gworlDomainName =
  process?.env?.NEXT_PUBLIC_GWORLD_DOMAIN_NAME ||
  'gworld.globalworkandtravel.com';

const GappGworldButton = () => {
  return (
    <a className="block my-1" href={`https://${gworlDomainName}`}>
      <i className="icon-profile pr--0.5 text-xl lg:text-base" />
    </a>
  );
};

export { GappGworldButton };
