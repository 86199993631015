import { getFavorites } from '@/Lib/helpers/favorites-local-storage';
import { useEffect, useState } from 'react';
import { AppLink } from '@/Components/link/link';
import { useAtomValue } from 'jotai';
import { wishlistAtom } from '@/State/global/global.store';

type Props = {
  className: string;
};

const WishlistMenu = ({ className }: Props) => {
  const [favCount, setFavCount] = useState<number>(0);
  const wishtlistTrigger = useAtomValue(wishlistAtom);

  useEffect(() => {
    const favorites = getFavorites();
    setFavCount(favorites.length);
  }, [wishtlistTrigger]);

  return (
    <AppLink
      href="/wishlist"
      className={`relative flex items-center text-black ${className}`}
    >
      <i className="icon-heart-outline text-xl  md:mr-2" />
      <span className="hidden lg:block text-sm pt-0.5">Wishlist</span>
      {favCount ? (
        <span className="absolute w-3 h-3 text-light-600 bg-dark-900 rounded-full text-3xs box-border text-center leading-3 left-3 top-2">
          {favCount}
        </span>
      ) : null}
    </AppLink>
  );
};

export default WishlistMenu;
