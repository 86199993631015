import { useState, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import {
  BasicTrip,
  Promotion,
  RecentDiscoveriesTrip,
  Trip,
} from '@/Lib/types/trip';
import {
  getAutoAndCookiePromotions,
  getBannerQualifiedPromotion,
  mergePromotions,
  validatePromotions,
} from './promotions.helper';
import { Promotions } from './promotions';
import { TCurrency } from '@/Lib/types/currency';
import { useCurrency } from '@/Lib/hooks/useCurrency';

const usePromotions = () => {
  const [promotions, setPromotions] = useState<Promotion[] | null>();
  const [bannerPromotion, setBannerPromotion] = useState<Promotion | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [height, setHeight] = useState<number>(0);
  const { currency } = useCurrency();
  const [cookies, setCookie, removeCookie] = useCookies(['promo']);
  const [isActive, setIsActive] = useState<boolean>(false);

  function getComputedPromos(
    firstPromotionsSet: Promotion[],
    secondPromotionsSet: Promotion[],
    trip: Trip | BasicTrip | RecentDiscoveriesTrip,
    tripPrice: number,
    tripDeposit: number,
    currency: TCurrency
  ): {
    discount: number;
    reducedDeposit: number;
    promoLink?: string;
    promotions: Promotion[];
  } {
    const mergedPromotions = mergePromotions(
      firstPromotionsSet,
      secondPromotionsSet
    );
    const validatedPromotions = validatePromotions(mergedPromotions, trip);
    const promotion = new Promotions(validatedPromotions, currency);
    promotion.getDiscount(tripPrice);
    const discount = promotion.discount;
    promotion.getReducedDeposit(tripDeposit);
    const reducedDeposit = promotion.reducedDeposit;
    return {
      discount: discount || 0,
      reducedDeposit: reducedDeposit || 0,
      promoLink: promotion.promoLink,
      promotions: promotion.validPromotions,
    };
  }

  // useCallback is used to prevent re-initializing the function since we have
  // a useEffect on this function in the provider to run this and init promos
  const initializePromotions = useCallback(
    async (queryStringPromo?: string) => {
      setIsLoading(true);
      const promotions = await getAutoAndCookiePromotions(
        currency,
        cookies,
        setCookie,
        removeCookie,
        queryStringPromo
      );
      setPromotions(promotions);
      const bannerPromotion = getBannerQualifiedPromotion(promotions, currency);
      setBannerPromotion(bannerPromotion);
      setIsLoading(false);
      setIsActive(!!bannerPromotion);
    },
    []
  );

  const closeBanner = () => {
    setHeight(0);
    setIsActive(false);
  };

  return {
    promotions,
    bannerPromotion,
    isLoading,
    initializePromotions,
    closeBanner,
    height,
    setHeight,
    getComputedPromos,
    isActive,
    setIsActive,
  };
};

export default usePromotions;
