// Reference: https://stackoverflow.com/a/201378
export const emailPattern =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export function getEmailValidation(email: string): boolean {
  return emailPattern.test(email);
}

export function getStrongPasswordValidation(password: string): boolean {
  const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
  return regex.test(password);
}

export function getSpecialCharValidation(password: string): boolean {
  const regex = /(?=.*[^A-Za-z0-9])/;
  return regex.test(password);
}

export function getLowCharCharValidation(password: string): boolean {
  const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
  return regex.test(password);
}

export function getLengthPassCharValidation(password: string): boolean {
  const regex = /(?=.{6,})/;
  return regex.test(password);
}

export function getNameValidation(text: string): boolean {
  const regex =
    /^[a-zA-Z\u00C0-\u017F\u0400-\u04FF\u0370-\u03FF\u0530-\u058F\u0590-\u05FF\u0600-\u06FF\u4E00-\u9FFF\u3040-\u30FF\uAC00-\uD7AF\s'-]+$/;

  return regex.test(text);
}

export function getNumberValidation(text: string): boolean {
  const regex = /^[0-9]*$/;
  return regex.test(text);
}
