import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { compareProductAtom, pageDetail } from '../global/global.store';
import { useRouter } from 'next/router';
import { useDevice } from '@/Lib/hooks/useDevice';
import { useCompare } from '@/Lib/hooks/useCompare';

const useCompareProductApp = () => {
  const setCompareList = useSetAtom(compareProductAtom);
  const pageDetailInfo = useAtomValue(pageDetail);
  const { RemoveAllCompares } = useCompare();
  const { isLG } = useDevice();
  const router = useRouter();
  const path = router.pathname;

  useEffect(() => {
    RemoveAllCompares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    const result =
      pageDetailInfo.name === 'division' &&
      pageDetailInfo.detail === 'working-holiday';
    if (result) {
      !isLG && document.body.classList.add('left-privacy');
    } else {
      document.body.classList.remove('left-privacy');
    }

    return () => {
      document.body.classList.remove('left-privacy');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDetailInfo]);
};

export { useCompareProductApp };
