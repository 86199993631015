import { useEffect, useState } from 'react';
import { useIsGAppUserAgent } from './useIsGAppUserAgent';
import { useWindowDimensions } from './useWindowDimensions';

const SM = 640;
const MD = 768;
const LG = 1024;

type Result = {
  isBase: boolean;
  isSM: boolean;
  isMD: boolean;
  isLG: boolean;
};

// NOTE: this tries to act as close to tailwind breakpoints
// as possible, that is why we don't have checks like
// MD < isMD < LG and instead use MD < isMD only
const useDevice = (): Result => {
  const isGapp = useIsGAppUserAgent();
  const { width } = useWindowDimensions();
  const [isBase, setIsBase] = useState<boolean>(true);
  const [isSM, setIsSM] = useState<boolean>(false);
  const [isMD, setIsMD] = useState<boolean>(false);
  const [isLG, setIsLG] = useState<boolean>(false);

  useEffect(() => {
    setIsBase(!!(width && width < SM));
    setIsSM(!!(width && width >= SM));
    setIsMD(!!(width && width >= MD));
    setIsLG(!!(width && width >= LG));
  }, [width]);

  if (isGapp) {
    return {
      isBase: true,
      isSM: false,
      isMD: false,
      isLG: false,
    };
  }

  return { isBase, isSM, isMD, isLG };
};

export { useDevice };
