import { useIsGAppUserAgent } from '@/Lib/hooks/useIsGAppUserAgent';
import { GappGworldButton } from '../gwat-menu/gapp-gworld-button';
import { GWorldButton } from './gworld-button';

type Props = {
  className?: string;
};

const GworldMenu = ({ className }: Props) => {
  const isGApp = useIsGAppUserAgent();

  return isGApp ? <GappGworldButton /> : <GWorldButton className={className} />;
};

export default GworldMenu;
