import { GetInTouchToggleContext } from 'layouts/MainLayout';
import { useContext } from 'react';
import { ModalCentered } from '../modals/modal-centered';
import { GetInTouchComponent } from './get-in-touch-component';

function GetInTouch(): JSX.Element {
  const getInTouchToggleContext = useContext(GetInTouchToggleContext);

  return (
    <>
      <ModalCentered
        isModalOpen={Boolean(getInTouchToggleContext?.isGetInTouchOpen)}
        setIsModalOpen={
          getInTouchToggleContext?.setIsGetInTouchOpen || (() => {})
        }
        hasAlternativeWidth={true}
        hasPreventDefault={false}
        hasMdRoudedBorders
      >
        <GetInTouchComponent />
      </ModalCentered>
    </>
  );
}

export { GetInTouch };
