type Props = {
  color?: string;
  width?: number;
  height?: number;
};
const ArrowRight = ({ color = 'white', width = 24, height = 24 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.98142 4L7 5.97683L13.0372 12L7 18.0232L8.98142 20L17 12L8.98142 4Z"
        fill={color}
      />
    </svg>
  );
};
export { ArrowRight };
