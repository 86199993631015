import { initializeApollo } from '@/Lib/graphql/apollo-client';
import { PROMOTION } from '@/Lib/graphql/queries/promotion.query';
import { Promotion } from '@/Lib/types/trip';

export async function fetchAutoPromotions(): Promise<Promotion[]> {
  const apolloClient = initializeApollo();

  const result = await apolloClient.query<{ promotions: Promotion[] }>({
    query: PROMOTION,
    fetchPolicy: 'no-cache',
  });

  return result.data.promotions;
}

export async function fetchPromotionByCode(code: string): Promise<Promotion[]> {
  const apolloClient = initializeApollo();

  const result = await apolloClient.query<{ promotions: Promotion[] }>({
    query: PROMOTION,
    variables: {
      codes: [code],
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.promotions;
}
