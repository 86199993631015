import { SmartStorage } from '@/Lib/services/smart-storage/smart-storage';
import { StorageKeys } from '@/Lib/services/storage-keys';
import { NotificationStorageData } from '@/Lib/types/social-proof';

const notificationSession = SmartStorage.createWithSessionStorage().over(
  StorageKeys.NotificationId
);

class NotificationStorageHelper {
  static set(payload: Partial<NotificationStorageData>): void {
    let data = this.get();
    if (!data) {
      data = { first: '', last: '' };
    }

    Object.keys(payload).forEach((k) => {
      const key = k as keyof NotificationStorageData;
      if (!payload[key]) return;
      if (!data) return;

      data[key] = payload[key] as string;
    });

    this.write(data);
  }

  static get(): NotificationStorageData | null {
    try {
      const raw = notificationSession.get();
      if (!raw) return null;

      const parsed = JSON.parse(raw);
      return parsed as NotificationStorageData;
    } catch (e) {
      return null;
    }
  }

  static reset(): void {
    notificationSession.remove();
  }

  private static write(data: NotificationStorageData): void {
    try {
      notificationSession.set(JSON.stringify(data));
    } catch (e) { }
  }
}

export { NotificationStorageHelper };
