import { AppLink } from '@/Components/link/link';
import { FC } from 'react';
import AboutLinks from '@/Components/header/header-about-menu/about-links';

type props = {
  activeMenu: string | null;
};

const HeaderAboutMenu: FC<props> = ({ activeMenu }) => {
  const isShown = activeMenu && activeMenu === 'How';

  return (
    <ul
      className={
        'nav-menu-fade-in text-left m-auto max-w-4xl gap-10 grid grid-cols-1 sm:grid-cols-3 py-8 pr-2 md:pl-24 md:pr-20 font-normal text-black' +
        (isShown ? ' block' : ' hidden')
      }
    >
      {AboutLinks.map((item) => (
        <li key={item.title} className="lg:w-50 lg:max-w-30 text-dark-900">
          <h3 className="font-semibold text-lg border-black mb-4 pb-2 border-b border-solid w-30">
            {item.title}
          </h3>
          <ul>
            {item.submenu.map((link) => (
              <li key={link.link} className="my-3 text-dark-700">
                {link.external ? (
                  <a href={link.link} target="_blank" rel="noreferrer">
                    {link.name}
                  </a>
                ) : (
                  <AppLink href={'/' + link.link}>{link.name}</AppLink>
                )}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default HeaderAboutMenu;
