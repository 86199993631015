import { useCallback, useEffect, useRef } from 'react';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith/isomorphic';
import { useRudderStack } from '@/Lib/hooks/rudderstack/useRudderstack';
import { useAtom, useSetAtom } from 'jotai';
import {
  flagsAreLoadingAtom,
  userIdentifiedStatusForAnalyticsAtom,
} from '@/State/global/global.store';
import { ABTestSetter } from './setter';
import debounce from 'lodash/debounce';

type Props = {
  children: React.ReactNode;
};

const ABTestProvider = ({ children }: Props) => {
  const { isIdentified, isIdentifiedRef, setIsIdentified } = useUserStatus();
  const { anonymousId } = useRudderStack();
  const setFlagsAreLoading = useSetAtom(flagsAreLoadingAtom);
  const ENVIRONMENTID = process?.env?.NEXT_PUBLIC_FLAGSMIT || '';

  const setHasLoaded = () => {
    setFlagsAreLoading(false);
  };
  const debouncedSetHasLoaded = debounce(setHasLoaded, 2000);

  // identify user once and only once
  useEffect(() => {
    if (!isIdentified && anonymousId && setIsIdentified) {
      flagsmith.identify(anonymousId);
      setIsIdentified(true);
    }
  }, [anonymousId, isIdentified, setIsIdentified]);

  return (
    <FlagsmithProvider
      options={{
        environmentID: ENVIRONMENTID,
        api: 'https://flags.globalworkandtravel.com/api/v1/',
        cacheFlags: false,
        onChange: () => {
          if (isIdentifiedRef.current) {
            debouncedSetHasLoaded();
          }
        },
      }}
      flagsmith={flagsmith}
    >
      <ABTestSetter>{children}</ABTestSetter>
    </FlagsmithProvider>
  );
};

// NOTE: dear future reader of this hook below, please don't be
// a smart-ass moving this into its own file. We specifically
// don't want this to be exported so it won't be used anywhere
// except for here, thanks.

const useUserStatus = () => {
  const [state, setState] = useAtom(userIdentifiedStatusForAnalyticsAtom);
  const ref = useRef(false);

  const set = useCallback(
    (value: boolean): void => {
      setState(value);
      ref.current = value;
    },
    [setState]
  );

  return {
    isIdentified: state,
    isIdentifiedRef: ref,
    setIsIdentified: set,
  };
};

export { ABTestProvider };
