import { MouseEvent, ReactNode } from 'react';
import { BaseButton } from './base';
import { TButtonSize, TButtonColor } from './button.type';
import { AppLink } from '@/Components/link/link';

export type Props = {
  text: string;
  href: string;
  color?: TButtonColor;
  size?: TButtonSize;
  icon?: string | ReactNode;
  clickHandler?: (e: MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  disabledTooltip?: string;
  isLoading?: boolean;
  className?: string;
  textClassName?: string;
  target?: string;
};

function LinkButton({
  text,
  href,
  color,
  size,
  icon,
  clickHandler,
  isDisabled = false,
  disabledTooltip,
  isLoading = false,
  className,
  textClassName,
  target = '_self',
}: Props) {
  return (
    <AppLink
      href={href}
      onClick={!isDisabled && clickHandler ? clickHandler : () => {}}
      title={isDisabled ? disabledTooltip : undefined}
      target={target}
    >
      <BaseButton
        text={text}
        color={color}
        size={size}
        icon={icon}
        isDisabled={isDisabled}
        isLoading={isLoading}
        className={className}
        textClassName={textClassName}
      />
    </AppLink>
  );
}

export { LinkButton };
