import { ArrowRight } from '@/Assets/icons/ArrowRight';
import { GImage } from '../g-image';

const AppStoreBtn = () => {
  return (
    <a
      href="https://apps.apple.com/us/app/global-work-travel/id1535421676?itsct=apps_box_link&itscg=30200"
      target="_blank"
      rel="noreferrer"
    >
      <div className="w-full md:w-15rem h-16 px-5 bg-dark-900 rounded-md flex items-center cursor-pointer ">
        <div className="flex items-center gap-x-5 flex-grow ">
          <div className="w-1.875rem h-1.875rem">
            <GImage
              path="website/icons/page/app-store.svg"
              alt="apple app store icon"
              disableLoadAnimation
            />
          </div>
          <div className="text-left">
            <div className="text-sm !leading-3 !font-medium text-light-600 ">
              Download on the
            </div>
            <div className="text-lg !leading-6 font-bold text-light-600 mt-1">
              App Store
            </div>
          </div>
        </div>
        <ArrowRight />
      </div>
    </a>
  );
};
export { AppStoreBtn };
