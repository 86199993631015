import { ReactNode } from 'react';
import { useSetPromotions } from '../hooks/promotions/useSetPromotions';
import { useCompareProductApp } from '../hooks/compare-product';

type Props = {
  children: ReactNode;
};

const SetterProvider = ({ children }: Props) => {
  useSetPromotions();
  useCompareProductApp();

  return <>{children}</>;
};

export { SetterProvider };
