import { PromotionsState } from '@/Lib/types/promotionts';
import SearchTabs from '@/Components/search-panel/search-tabs';
import { atom, createStore } from 'jotai';
import { compareObj } from '@/Lib/types/compare';
import { TUser } from '@/DataTypes/user';
import { TCurrencyChangeTripData } from '@/Lib/types/currency';
import { TPageDetail } from '@/Lib/types/page';

export const globalStateStore = createStore();

export const promotionsAtom = atom<PromotionsState>({});
export const promoUpdateTriggerAtom = atom<number>(0);

export const wishlistAtom = atom<number>(0);

export const activeTabAtom = atom<string>(SearchTabs.WORK);

export const userLoginStatusAtom = atom<boolean | null>(null);

export const userEmailAtom = atom<string | null>(null);

export const compareProductAtom = atom<compareObj[] | null>(null);
export const userAtom = atom<TUser | null>(null);

export const flagsAreLoadingAtom = atom<boolean>(true);

export const userIdentifiedStatusForAnalyticsAtom = atom<boolean>(false);

export const isInTripPageAtom = atom<boolean>(false);

export const currencyChangeTripDataAtom = atom<TCurrencyChangeTripData>({
  id: null,
  name: null,
});

export const pageDetail = atom<TPageDetail>({
  name: null,
  detail: '',
});
