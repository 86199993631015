type Props = {
  text: string;
  isActive: boolean;
};

const Button = ({ text, isActive }: Props) => {
  return (
    <button
      className={
        'w-full md:w-fit md:min-w-13 md:px-8 md:mx-auto mt-2.5 py-1 md:py-2 text-base rounded-md leading-6 text-light-600 transition-colors duration-500' +
        (isActive ? ' bg-teal-800 group-hover:bg-teal-900' : ' bg-light-900')
      }
    >
      {text}
    </button>
  );
};

export { Button };
