class CookieHelper {
  // taken from: https://www.w3schools.com/js/js_cookies.asp
  static getByName(cname: string) {
    try {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    } catch (e) {
      console.error(
        'Something went wrong while trying to parse cookies from document.cookie'
      );
      return '';
    }
  }
}

export { CookieHelper };
