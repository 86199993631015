import { Dispatch, SetStateAction } from 'react';
import { ModalCentered } from '../modals/modal-centered';

type Props = {
  alert: boolean;
  setAlert: Dispatch<SetStateAction<boolean>>;
};

const LiveChatAlert = ({ alert, setAlert }: Props) => {
  return (
    <ModalCentered
      isModalOpen={alert}
      setIsModalOpen={setAlert || (() => {})}
      hasAlternativeWidth={true}
      hasPreventDefault={false}
      hasMdRoudedBorders
    >
      <div className="w-31rem">
        <p>
          We are unable to launch the live chat, please ensure you have
          javascript enabled and ad-blockers turned off.
        </p>
      </div>
    </ModalCentered>
  );
};

export { LiveChatAlert };
