import { FC } from 'react';

import { AppLink } from '@/Components/link/link';
import WhyUsLinks from '@/Components/header/header-why-us-menu/why-us-links';

type props = {
  activeMenu: string | null;
};

const HeaderWhyUsMenu: FC<props> = ({ activeMenu }) => {
  const isShown = activeMenu && activeMenu === 'Why';
  const items = WhyUsLinks;

  return (
    <ul
      className={
        'nav-menu-fade-in text-left m-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-8 gap-16 px-4 xs:px-2 sm:px-14 py-8 font-normal max-w-4xl' +
        (isShown ? ' block' : ' hidden')
      }
    >
      {items.map((i) => (
        <li key={i.link} className="cursor-pointer text-dark-900">
          <AppLink href={'/' + i.link}>
            <h3 className="font-semibold text-lg">{i.title}</h3>

            <p className="text-dark-700 text-sm leading-4.5 my-1.5">
              {i.description}
            </p>
          </AppLink>
        </li>
      ))}
    </ul>
  );
};

export default HeaderWhyUsMenu;
