import { UserLocation } from '@/Lib/types/location';
import { PhoneNumbers, TOffice } from '@/Lib/types/office';

const officeKeyByLocation: {
  [key: string]: string;
} = {
  Americas: 'Canada',
  Europe: 'United Kingdom',
  Ocenia: 'Australia',
  default: 'Australia',
};

export class OfficeHelper {
  static getPhone(location: UserLocation, phoneNumbers: PhoneNumbers): string {
    if (phoneNumbers) {
      const phone: string =
        phoneNumbers[location.country.code] ||
        phoneNumbers[location.continent] ||
        phoneNumbers.default;
      return phone;
    } else {
      return '';
    }
  }

  static getOfficeName(location: UserLocation): string {
    const officeName =
      officeKeyByLocation[location.continent] || officeKeyByLocation.default;
    return officeName;
  }

  static isOfficeOpen(office: TOffice): boolean {
    const now = new Date(
      new Date().getTime() + office?.open?.offset * 60 * 60 * 1000
    );
    // @ts-ignore
    const day = office?.open?.times[`_${now.getUTCDay()}`];
    if (!day) {
      return false;
    }

    const [openTime, closeTime] = day;

    const currentTime = `${String(now.getUTCHours())}:${String(
      now.getUTCMinutes()
    )}`;

    return (
      this.timeIsValid(openTime, currentTime, (a, b) => a <= b) &&
      this.timeIsValid(closeTime, currentTime, (a, b) => a >= b)
    );
  }

  static timeIsValid(
    time: string,
    now: string,
    comparitor: (a: number, b: number) => boolean
  ) {
    const [timeHours, timeMinutes] = time.split(':').map(Number);
    const [nowHours, nowMinutes] = now.split(':').map(Number);
    const open =
      timeHours === nowHours
        ? comparitor(timeMinutes, nowMinutes)
        : comparitor(timeHours, nowHours);
    return open;
  }
}
