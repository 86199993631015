import { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { ModalHeader } from './header';
import { useDisabledDocumentScroll } from '@/Lib/hooks/useDisabledDocumentScroll';

const customModalStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: '#17181A99',
  },
  content: {
    border: 'none',
    backgroundColor: 'transparent',
  },
};

export type Props = {
  isOpen: boolean;
  close: Function;
  title: string;
  children: JSX.Element[] | JSX.Element;
  position?: string;
  cssClasses?: string;
  isCloseButtonHidden?: boolean;
  isCloseClickOutside?: boolean;
  titleCssCalss?: string;
  mobileTitleCss?: string;
};

const Modal = ({
  isOpen,
  close,
  title,
  children,
  position = 'bottom',
  cssClasses,
  isCloseButtonHidden = false,
  isCloseClickOutside = true,
  titleCssCalss = ' p-10 ',
  mobileTitleCss,
}: Props) => {
  const mainRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    isCloseClickOutside &&
      document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCloseClickOutside]);

  const handleClickOutside = (e: any) => {
    if (
      mainRef?.current?.contains(e.target) &&
      !contentRef?.current?.contains(e.target)
    ) {
      close();
    }
  };

  useDisabledDocumentScroll({
    isDisabled: isOpen,
  });

  return (
    <ReactModal
      isOpen={isOpen}
      style={customModalStyles}
      closeTimeoutMS={300}
      ariaHideApp={false}
    >
      <div
        data-testid="modal"
        ref={mainRef}
        className={
          'z-50 fixed overflow-hidden top-0 right-0 left-0 bottom-0 w-full h-full max-h-screen bg-transparent flex justify-center items-center' +
          (isCloseButtonHidden ? '' : ' cursor-pointer')
        }
        style={{
          WebkitTapHighlightColor: 'transparent',
        }}
      >
        <div
          ref={contentRef}
          className={
            `bg-light-700 shadow-modalfilter cursor-auto absolute sm:relative m-auto left-0 right-0 rounded-t-2xl md:rounded-md w-full ` +
            (position === 'bottom' ? ' bottom-0 ' : ' top-0 ') +
            cssClasses
          }
        >
          <div className="modal-content-inner relative text-center">
            <ModalHeader
              title={title}
              titleCssClasses={titleCssCalss}
              isCloseButtonHidden={isCloseButtonHidden}
              mobileTitleCss={mobileTitleCss}
              close={close}
            />
            <div data-testid="child" className="modal-content-children-wrapper">
              {children}
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export { Modal };
