import { ReactNode, useEffect } from 'react';
import { useAppFlags } from '@/Lib/hooks/ab-test/useAppFlags';
import { useLocation } from '../useLocation';
import { useCurrency } from '../useCurrency';
import { useSetUserLoginStatus } from '@/State/hooks/user-login-status';

type Props = {
  children: ReactNode;
};

const ABTestSetter = ({ children }: Props) => {
  const { setTraits } = useAppFlags();
  const { location } = useLocation();
  const { currency } = useCurrency();
  useSetUserLoginStatus();

  useEffect(() => {
    setTraits({
      currency: currency,
      country: location.country.name,
      last_visit: Math.ceil(new Date().getTime() / 1000),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.country.name]);

  return <>{children}</>;
};

export { ABTestSetter };
