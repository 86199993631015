const CompareDesktopSkeleton = () => {
  return (
    <>
      <div className="hidden md:flex h-[6.25rem] rounded-md border border-light-800 items-center justify-center relative">
        <div className="flex justify-start gap-x-2.5 w-full p-5">
          <div className="h-[3.75rem] min-h-[3.75rem] w-[5em] min-w-[5rem] bg-light-800 animate-pulse rounded-md "></div>
          <div className="text-sm text-dark-800 font-semibold flex items-start flex-col justify-center gap-y-1 w-full">
            <div className=" h-4 w-20 bg-light-800 animate-pulse rounded-md "></div>
            <div className=" h-3 w-[3.5rem] bg-light-800 animate-pulse rounded-md "></div>
          </div>
        </div>
      </div>
      <div className="flex md:hidden p-5 border border-light-800 items-center gap-x-2.5 rounded-md">
        <div className="w-20 h-[3.75rem] bg-light-800 animate-pulse rounded-md"></div>
        <div className="flex flex-col gap-y-1.25">
          <div className="flex justify-start items-center gap-x-1 text-base !leading-6 text-dark-800">
            <div className=" h-3 w-[4rem] bg-[#CCCCCC] animate-pulse rounded-md "></div>
            <div className=" h-3 w-[4rem] bg-light-800  animate-pulse rounded-md "></div>
          </div>
        </div>
      </div>
    </>
  );
};

export { CompareDesktopSkeleton };
