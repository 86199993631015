import { DEPOSIT } from '@/Lib/graphql/queries/promotion.query';
import { useCurrency } from '@/Lib/hooks/useCurrency';
import { Price, Promotion } from '@/Lib/types/trip';
import { useQuery } from '@apollo/client';
import { currencySymbols } from 'data/currency';

type Props = {
  data: Promotion | null | undefined;
};

const replace = (text: string, trigger: string, replacement: string) => {
  return text.replace(trigger, replacement);
};

const usePromotionText = ({ data }: Props) => {
  const { currency } = useCurrency();

  const {
    loading: depositLoading,
    data: depositData,
    error: depositError,
  } = useQuery<{ trip_deposit_prices: Price }>(DEPOSIT);

  if (depositLoading) return { text: '', isLoading: true };
  if (!depositData) return { text: '', isLoading: false };
  if (!data?.ec_banner_text || !data?.amount) {
    return {
      text: data?.ec_banner_text ?? '',
      isLoading: false,
    };
  }

  const amount = currencySymbols[currency] + String(data.amount[currency]);
  const withAmountReplaced = replace(data.ec_banner_text, '{{amount}}', amount);

  if (
    data.type === 'reduced_deposit' &&
    (data.ec_banner_text.indexOf('{{fullDeposit}}') > -1 ||
      (data.ec_banner_text.indexOf('{{discountDeposit}}') &&
        data.amount[currency]))
  ) {
    const deposit = depositData.trip_deposit_prices;
    const fullDeposit = deposit[currency];
    const discountDeposit = deposit[currency] - (data.amount[currency] || 0);

    const withFullDepositReplaced = replace(
      withAmountReplaced,
      '{{fullDeposit}}',
      currencySymbols[currency] + String(fullDeposit)
    );
    const withDiscountedDepositReplaced = replace(
      withFullDepositReplaced,
      '{{discountDeposit}}',
      currencySymbols[currency] + String(discountDeposit)
    );
    return {
      text: withDiscountedDepositReplaced,
      isLoading: false,
    };
  }

  return {
    text: withAmountReplaced,
    isLoading: depositLoading,
  };
};

export { usePromotionText };
