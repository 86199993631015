import { useContext } from 'react';
import { SharedDataContext } from './shared-static-data.provider';

const useSharedStaticData = <T>(): T => {
  const context = useContext<T>(SharedDataContext);

  if (context === undefined) {
    throw new Error('useStaticData must be used within a StaticDataProvider');
  }
  if (context === null) {
    throw new Error('data must be available at all times');
  }

  return context;
};

export { useSharedStaticData };
