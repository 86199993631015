import { StringHelper } from '@/Lib/helpers/string';
import { useEffect, useState } from 'react';

declare const rudderanalytics: any;

declare global {
  interface Window {
    rudderstackIsLoaded: boolean | undefined;
  }
}

let tries = 0;
const MAX_TRIES = 15;

export const useRudderstackID = (): string | null => {
  const [id, setId] = useState<string | null>(null);

  useEffect(() => {
    const rudderInterval = setInterval(() => {
      if (tries > MAX_TRIES && !window.rudderstackIsLoaded) {
        clearInterval(rudderInterval);
        setId(StringHelper.generateRandomString(32));
      }

      if (
        !id &&
        typeof window !== 'undefined' &&
        typeof rudderanalytics !== 'undefined'
      ) {
        if (rudderanalytics.getAnonymousId()) {
          setId(rudderanalytics.getAnonymousId());
          clearInterval(rudderInterval);
        }
      }

      tries++;
    }, 1000);

    return () => clearInterval(rudderInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return id;
};
