import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Retention } from '@/Lib/helpers/retention';

declare const rudderanalytics: any;

export const useRudderstackPage = () => {
  const { asPath, route } = useRouter();

  const path = asPath.split('?')[0];

  useEffect(() => {
    if (!path.startsWith('/login/callback')) {
      rudderanalytics.page(route, path, {
        path,
        url: 'https://www.globalworkandtravel.com' + path,
      });
      Retention.page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
};
