// eslint-disable-next-line no-restricted-imports
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { QueryParam } from '@/Lib/services/query-param';

// This wraps nextjs <Link /> because we need to keep query params
// (especially marketing ones) that are given to us from outside
const AppLink = ({
  href,
  children,
  ...props
}: LinkProps & PropsWithChildren<any>) => {
  const router = useRouter();
  const existingParams = QueryParam.getAll(router.asPath);

  const pathname = typeof href === 'object' ? href.pathname : href;

  const givenParams =
    typeof href === 'object' && typeof href.query === 'object'
      ? href.query
      : {};

  if (typeof href === 'string') {
    // If href is a string, use it directly without modifying
    return (
      <NextLink {...props} href={href}>
        {children}
      </NextLink>
    );
  }

  const filteredExistingParams = QueryParam.omitBlacklisted(existingParams);

  const filteredWithNewParams = { ...filteredExistingParams, ...givenParams };

  return (
    <NextLink
      {...props}
      href={{ pathname: pathname, query: filteredWithNewParams }}
    >
      {children}
    </NextLink>
  );
};

export { AppLink };
