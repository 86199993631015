import React, { useEffect, useState } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { name } from '@cloudinary/url-gen/actions/namedTransformation';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { Dpr } from '@cloudinary/url-gen/qualifiers/dpr';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { CSSProperties } from 'react';
import dayjs from 'dayjs';
import { getAssetVersion } from '@/Lib/function/assets-version';
const cloudinaryUrl =
  process?.env?.NEXT_PUBLIC_WEBSITE_CLOUDINARY_CLOUD_NAME || 'gwatco';

function startsWith(str: string, word: string) {
  return str.lastIndexOf(word, 0) === 0;
}

function endsWithSvg(str: string) {
  return str.endsWith('.svg');
}

const generateUrl = (
  path: string,
  crop_w?: number,
  crop_h?: number,
  transformation?: string,
  useGallery?: boolean,
  specificGallery?: string
) => {
  const cloudinary = new Cloudinary({
    cloud: {
      cloudName: specificGallery
        ? specificGallery
        : useGallery
        ? cloudinaryUrl
        : 'gwatco',
    },
    url: {
      analytics: false,
    },
  });

  const version = getAssetVersion();

  try {
    const result = startsWith(path, 'http');
    if (result) {
      return path;
    }
    const image = cloudinary.image(path);
    image.setVersion(version);
    image.delivery(dpr(Dpr.auto()));
    if (crop_w && crop_h) {
      image.resize(fill(crop_w, crop_h));
    }
    if (transformation) {
      image.namedTransformation(name(transformation));
    }
    if (endsWithSvg(path)) {
      return image.toURL();
    }
    return image.format('auto').toURL();
  } catch (e) {
    return '';
  }
};

interface GImageInputTypes {
  path: string;
  alt: string;
  transformation?: string;
  classes?: string;
  styles?: CSSProperties;
  height?: string;
  width?: string;
  crop_h?: number;
  crop_w?: number;
  hasLoadingBackground?: boolean;
  loadFn?: any;
  useGallery?: boolean;
  specificGallery?: string;
  disableLoadAnimation?: boolean;
  onClick?: any;
  visibleByDefault?: boolean;
  wrapperClass?: string;
}

function GImage({
  path,
  alt,
  transformation,
  classes,
  styles,
  height,
  width,
  crop_h,
  crop_w,
  hasLoadingBackground = false,
  loadFn,
  useGallery = false,
  specificGallery,
  disableLoadAnimation = false,
  onClick,
  visibleByDefault = false,
  wrapperClass = '',
}: GImageInputTypes): JSX.Element {
  const [isLoaded, setIsLoaded] = useState<boolean>(visibleByDefault);
  const afterLoad = () => {
    setIsLoaded(true);
    loadFn && loadFn(true);
  };

  return (
    <span
      className={
        'inline-block w-full h-full flex items-center ' +
        wrapperClass +
        (isLoaded
          ? ' bg-transparent'
          : hasLoadingBackground
          ? ' bg-light-900 animate-pulse'
          : ' bg-transparent')
      }
      onClick={onClick}
    >
      <LazyLoadImage
        style={{ width: '100%', height: '100%', ...styles }}
        height={height}
        width={width}
        className={'object-cover object-center ' + (classes ? classes : '')}
        alt={alt}
        src={generateUrl(
          path,
          crop_w,
          crop_h,
          transformation,
          useGallery,
          specificGallery
        )}
        effect={disableLoadAnimation ? undefined : 'blur'}
        afterLoad={() => afterLoad()}
        visibleByDefault={visibleByDefault}
      />
    </span>
  );
}

export { GImage, generateUrl };
