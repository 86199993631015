import { GImage } from '@/Components/g-image';
import { AppLink } from '@/Components/link/link';
import { HeaderNavigationDivision } from '@/Lib/types/header-navigation';

type Props = { division: HeaderNavigationDivision; isCominSoon?: boolean };

// before you say anything, this functionality was statically generated in the
// previous angular version, I noticed we have all those names in secondaryName
// field on our divisions, except for summer camp, hence the function below
const getSecondaryName = (name: string): string => {
  return name === 'Summer Camp' ? 'Camp' : name;
};

const Card = ({ division, isCominSoon = false }: Props) => {
  const toInternalName = (type: string) => {
    return type.toLowerCase().split(' ').join('-');
  };

  const imagePath = (type: string) => {
    return 'website/division/' + toInternalName(type) + '/banner.jpg';
  };
  return (
    <AppLink
      href={isCominSoon ? '' : '/' + division.internalName || ''}
      className={
        'text-left select-none ' + (isCominSoon ? 'pointer-events-none' : '')
      }
    >
      <div
        key={division.name}
        className={
          'relative overflow-hidden ' + (isCominSoon ? '' : 'cursor-pointer')
        }
      >
        {isCominSoon && (
          <div className="absolute bg-red-900 text-light-600 z-10 -rotate-45 -left-9 top-6 text-center text-xs font-bold overflow-hidden py-1 px-8 whitespace-nowrap">
            Coming soon{' '}
          </div>
        )}
        <div className="w-fit md:w-auto" style={{ height: '8.125rem' }}>
          <GImage
            path={imagePath(division.type)}
            alt={division.name}
            height={'190'}
            transformation="trips-dropdown"
            classes={'rounded ' + (isCominSoon ? 'grayscale' : '')}
            hasLoadingBackground
          />
        </div>
        <div>
          <h3 className="font-semibold text-lg mb-1 mt-2 capitalize">
            {getSecondaryName(division.secondaryName)}
          </h3>
          <div
            className="text-darkgrey text-base leading-5"
            dangerouslySetInnerHTML={{ __html: division.summary }}
          />
        </div>
      </div>
    </AppLink>
  );
};
export default Card;
