import { defaultLocation } from 'data/default-location';
import { CookieHelper } from '../helpers/cookie';
import { UserLocation } from '../types/location';

const LOCATION_COOKIE_NAME = 'x-request-location';

function useLocation() {
  let cookieLocation: UserLocation | null = null;

  if (typeof document !== 'undefined') {
    const cookieString = CookieHelper.getByName(LOCATION_COOKIE_NAME);
    try {
      if (cookieString.length > 0) {
        cookieLocation = JSON.parse(cookieString) as UserLocation;
      }
    } catch (e) {
      console.error('Error: Parsing location cookie failed.');
    }
  }

  const location = cookieLocation ?? defaultLocation;

  return { location };
}

export { useLocation };
