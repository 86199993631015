import {IStorage} from "@/Lib/services/smart-storage/IStorage";

export class LocalStorage implements IStorage {
  set(key: string, value: string) {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(key, value);
  }

  get(key: string) {
    if (typeof window === 'undefined') return null;
    return window.localStorage.getItem(key) || null;
  }

  remove(key: string) {
    if (typeof window === 'undefined') return;
    window.localStorage.removeItem(key);
  }
}