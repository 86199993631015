import { useCallback } from 'react';
import {
  getCurrencyByLocation,
  getCurrencyIsChangable,
} from '../helpers/currency';
import { TCurrency } from '../types/currency';
import { useLocation } from './useLocation';
import { useCookies } from 'react-cookie';

const USER_CURRENCY_COOKIE = 'user-currency';
const DOMINANT_CURRENCY_COUNTRIES = ['US', 'AU'];
const UNAMBIGUOUS_CURRENCIES = ['GBP', 'EUR'];

const useCurrency = () => {
  const { location } = useLocation();
  const [cookies, setCookie] = useCookies([USER_CURRENCY_COOKIE]);

  const locationCurrency = getCurrencyByLocation(location);
  const userSelectedCurrency: TCurrency = cookies[USER_CURRENCY_COOKIE];

  const currency = userSelectedCurrency ?? locationCurrency;

  const currencyShouldBeShown =
    !DOMINANT_CURRENCY_COUNTRIES.includes(location.country.code) &&
    !UNAMBIGUOUS_CURRENCIES.includes(currency);

  const currencyIsChangeable = getCurrencyIsChangable(location);

  const setCurrency = useCallback(
    (currency: TCurrency) => {
      setCookie(USER_CURRENCY_COOKIE, currency);
    },
    [setCookie]
  );

  return {
    currency,
    currencyShouldBeShown,
    setCurrency,
    locationCurrency,
    currencyIsChangeable,
  };
};

export { useCurrency };
