import React, { useState, FC, useEffect } from 'react';

import CallButton from '../call-button/call-button';
import GworldMenu from '@/Components/header/gwat-menu/gworld-menu';
import WishlistMenu from '@/Components/header/wishlist-menu';
import MobileSideNav from '@/Components/mobile-side-nav/mobile-side-nav';
import { useRouter } from 'next/router';
import { useOffice } from '@/Lib/hooks/office/useOffice';
import { HeaderNavigation } from '@/Lib/types/header-navigation';

type Props = {
  headerHeight: number;
  navigationData: HeaderNavigation;
};

const HeaderMenuMobile: FC<Props> = ({ headerHeight, navigationData }) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const { isOpen: isOfficeOpen } = useOffice();

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setIsOpen(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuClasses = `${isOpen ? 'is-active' : ''}`;

  const handleMenuToggle = (state: boolean) => {
    setIsOpen(state);
  };

  return (
    <div className={`lg:hidden h-full flex items-center pointer-events-auto`}>
      <div className="flex items-center gap-4">
        {!isOfficeOpen ? (
          <>
            <WishlistMenu className="flex items-center text-xl" />
            <GworldMenu className="flex lg:hidden" />
          </>
        ) : null}
        <CallButton />
      </div>
      <button
        aria-label="sidebar toggle"
        className={`hamburger hamburger--collapse mr-px h-full relative ${menuClasses} ${
          isOfficeOpen ? ' ml-2.5' : ' ml-5'
        }`}
        type="button"
        onClick={() => {
          handleMenuToggle(!isOpen);
        }}
      >
        <span className="hamburger-box top-0.5">
          <span className="hamburger-inner" />
        </span>
      </button>
      <nav>
        <MobileSideNav
          navigationData={navigationData}
          headerHeight={headerHeight}
          isOpen={isOpen}
        />
      </nav>
    </div>
  );
};

export default HeaderMenuMobile;
