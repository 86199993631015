import { useEffect, useState } from 'react';
import usePromotions from '@/State/hooks/promotions/promotions.hook';
import { useRouter } from 'next/router';
import { useSetAtom } from 'jotai';
import { promotionsAtom } from '@/State/global/global.store';
import { QueryParam } from '@/Lib/services/query-param';

const useSetPromotions = () => {
  const promotions = usePromotions();
  const router = useRouter();
  const setPromotions = useSetAtom(promotionsAtom);

  useEffect(() => {
    setPromotions(promotions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotions]);

  useEffect(() => {
    const promo = QueryParam.getFirst(router.asPath, 'promo');
    if (promotions.initializePromotions) {
      promotions.initializePromotions(promo ?? undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotions.initializePromotions]);
};

export { useSetPromotions };
