import { useRouter } from 'next/router';
import { UrlObject } from 'node:url';
import { QueryParam } from '../services/query-param';
import { ParamObject } from '../types/query-param';

// next.js does not expose this type
interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

const useCustomRouter = () => {
  const router = useRouter();
  const existingParams = QueryParam.getAll(router.asPath);

  const customPush = async (
    url: UrlObject | string,
    params?: ParamObject
  ): Promise<boolean> => {
    const filteredExistingParams = QueryParam.omitBlacklisted(existingParams);
    const filteredWithNewParams = { ...filteredExistingParams, ...params };
    const stringified = QueryParam.stringify(filteredWithNewParams);
    return await router.push(`${url}?${stringified}`);
  };

  const customReplace = async (
    url: UrlObject | string,
    params?: ParamObject,
    options?: TransitionOptions
  ): Promise<boolean> => {
    const filteredExistingParams = QueryParam.omitBlacklisted(existingParams);
    const filteredWithNewParams = { ...filteredExistingParams, ...params };
    const stringified = QueryParam.stringify(filteredWithNewParams);
    return await router.replace(`${url}?${stringified}`, undefined, options);
  };

  return { ...router, push: customPush, replace: customReplace };
};

export { useCustomRouter };
