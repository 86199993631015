import { Price } from '../types/trip';
import { useFlags } from 'flagsmith/react';

const useDynamicPrice = (division: string): Price | null => {
  const flagId = `price:${division}`;
  const flags = useFlags([flagId]);
  const price = flags[flagId];

  if (price && price.enabled && price.value) {
    try {
      const parsed = JSON.parse(price.value as string);
      for (let key of Object.keys(parsed)) {
        parsed[key] = Number(parsed[key]);
      }
      return parsed as Price;
    } catch (e) {
      return null;
    }
  }

  return null;
};

export { useDynamicPrice };
