import { useState } from 'react';

import Header from '@/Components/header';
import Footer from '@/Components/footer';
import { GetInTouch } from '@/Components/get-in-touch/get-in-touch';
import React from 'react';
import { BookACall } from '@/Components/book-a-call/book-a-call';
import { useRouter } from 'next/router';
import { SocialProof } from '@/Components/social-proof/social-proof';
import { useIsGAppUserAgent } from '@/Lib/hooks/useIsGAppUserAgent';
import { CompareProducts } from '@/Components/compare-products';
import { HeaderNavigation } from '@/Lib/types/header-navigation';

export const GetInTouchToggleContext = React.createContext<{
  isGetInTouchOpen: boolean;
  setIsGetInTouchOpen: Function;
} | null>(null);

export const BookACallContext = React.createContext<{
  isBookACallOpen: boolean;
  setIsBookACallOpen: Function;
  tripId: number;
  setTripId: Function;
} | null>(null);

export const HeaderVisiblityContext = React.createContext<{
  isHeaderVisible: boolean;
  setIsHeaderVisible: Function;
} | null>(null);

type Props = {
  children: React.ReactNode;
  pageProps: { navigationData: HeaderNavigation; headerData: any } & unknown;
};

const MainLayout = ({
  children,
  pageProps: { navigationData, headerData },
}: Props) => {
  const router = useRouter();
  const [isGetInTouchOpen, setIsGetInTouchOpen] = useState<boolean>(false);
  const [isBookACallOpen, setIsBookACallOpen] = useState<boolean>(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(true);
  const [tripId, setTripId] = useState<number>(0);
  const isGApp = useIsGAppUserAgent();

  function isFullLayout(): boolean {
    return (
      !router.pathname.startsWith('/lp/') &&
      !router.pathname.startsWith('/auth/')
    );
  }

  return (
    <div>
      {isFullLayout() ? (
        <GetInTouchToggleContext.Provider
          value={{ isGetInTouchOpen, setIsGetInTouchOpen }}
        >
          <BookACallContext.Provider
            value={{ isBookACallOpen, setIsBookACallOpen, tripId, setTripId }}
          >
            <HeaderVisiblityContext.Provider
              value={{ isHeaderVisible, setIsHeaderVisible }}
            >
              <Header headerData={headerData} navigationData={navigationData} />
              <GetInTouch />
              <BookACall />
              <SocialProof />
              {children}
              {!isGApp && <Footer />}
            </HeaderVisiblityContext.Provider>
          </BookACallContext.Provider>
        </GetInTouchToggleContext.Provider>
      ) : (
        <>{children}</>
      )}
      <CompareProducts />
    </div>
  );
};

export default MainLayout;
