import dayjs from "dayjs";

function getFirstOrFifteenth() {
  const date = dayjs();
  const dayOfMonth = date.date();

  if (dayOfMonth < 15) {
    return date.startOf('month').unix();
  } else {
    return date.date(15).startOf('day').unix();
  }
}

export function getAssetVersion() {
  return getFirstOrFifteenth();
}