import {SmartStorage} from "@/Lib/services/smart-storage/smart-storage";
import {StorageKeys} from "@/Lib/services/storage-keys";

const favoritesStore = SmartStorage.createWithLocalStorage().over(StorageKeys.Favorites);

export function toggleFavoriteLocalStorage(tripId: number): boolean {
  if (window) {
    const favorites = getFavorites();
    if (favorites.length > 0) {
      // if found in favs, must remove
      if (favorites.find((item) => item === tripId)) {
        const updatedFavorites = favorites.filter((item) => item !== tripId);
        favoritesStore.set(
          JSON.stringify(updatedFavorites)
        );
        return true;
      } else {
        // if not found, add
        favorites.push(tripId);
        favoritesStore.set(
          JSON.stringify(favorites)
        );
        return true;
      }
    } else {
      favoritesStore.set(JSON.stringify([tripId]));
      return true;
    }
  }
  return false;
}

export function getFavorites(): number[] {
  if (window) {
    const favorites = JSON.parse(
      String(favoritesStore.get())
    );
    return favorites || [];
  }
  return [];
}
