import WishlistMenu from '@/Components/header/wishlist-menu';
import CallButton from '@/Components/header/call-button/call-button';
import { useDevice } from '@/Lib/hooks/useDevice';
import { useOffice } from '@/Lib/hooks/office/useOffice';
import GworldMenu from '@/Components/header/gwat-menu/gworld-menu';
import { SocialProof } from '@/Components/header/social-proof/social-proof';
import { HeaderData } from '@/Lib/types/header';
import { useEffect, useState } from 'react';

type Props = {
  headerData: HeaderData;
};

const HeaderMenuTop = ({ headerData }: Props) => {
  const { isLG } = useDevice();
  const { isOpen } = useOffice();
  const [shouldShowRightPart, setShouldShowRightPart] =
    useState<boolean>(false);

  // NOTE: this shenanigan is because of nextjs being stupid not to recognize
  // that the client code would be different than the server code
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setShouldShowRightPart(isOpen || isLG);
    }
  }, [isOpen, isLG]);

  return (
    <div className="py-2">
      <div
        className={
          'flex bg-white items-center  max-w-6.75xl font-semibold mx-auto px-5 lg:px-10' +
          (isOpen ? ' justify-between' : ' justify-center')
        }
      >
        <div className="hidden md:flex mr-auto flex-1-only"></div>
        <div className="place-self-center">
          <SocialProof headerData={headerData} />
        </div>
        {shouldShowRightPart ? (
          <div className="flex flex-row justify-end items-center text-sm gap-x-4 ml-auto flex-1-only">
            <WishlistMenu className="h-full" />
            {isLG ? <CallButton /> : <GworldMenu />}
          </div>
        ) : (
          <div className="hidden md:block flex-1-only"></div>
        )}
      </div>
    </div>
  );
};

export default HeaderMenuTop;
