const WhyUsLinks = [
  {
    title: 'Why go Global',
    description: 'Discover what makes us the leading work & travel company.',
    link: 'why-go-global',
  },
  {
    title: 'Reviews',
    description: 'Real stories from real customers from all around the world.',
    link: 'reviews',
  },
  {
    title: 'gWorld',
    description:
      'Stay organised for your upcoming trip with your very own personalised trip portal.',
    link: 'gworld',
  },
  {
    title: 'Lifetime deposits',
    description:
      'In case your plans change our deposits last forever and are fully transferrable.',
    link: 'deposit',
  },
  //{
  //title: 'Students',
  //description:
  //'Set yourself up for success with our real-world experiences and a special discount to help.',
  //link: 'students',
  //},
  {
    title: 'Parents',
    description:
      'We understand it’s a big deal, so we’ve got lots of information to share with you.',
    link: 'parents',
  },
  {
    title: 'Financial security',
    description:
      'Feel confident knowing your life-investment will deliver returns.',
    link: 'financial-security',
  },
];

export default WhyUsLinks;
