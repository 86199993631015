import { Promotion, TPromotionTime } from '@/Lib/types/trip';
import { useEffect, useState } from 'react';

type Props = {
  promotion: Promotion;
};

const usePromotionTimer = ({ promotion }: Props) => {
  const [endTime, setEndTime] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<TPromotionTime>({
    days: '00',
    hours: '00',
    mins: '00',
    secs: '00',
  });

  useEffect(() => {
    if (promotion && promotion.first_seen && promotion.end_at) {
      const countDownDate = new Date(promotion.first_seen * 1000);
      countDownDate.setTime(countDownDate.getTime() + promotion.end_at * 1000);
      setEndTime(countDownDate.getTime());
    }

    function runTimer() {
      var now = new Date().getTime();

      var distance = endTime - now;

      var days = Math.floor(distance / (1000 * 60 * 60 * 24))?.toLocaleString(
        'en-US',
        { minimumIntegerDigits: 2, useGrouping: false }
      );
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )?.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      var mins = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      )?.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      var secs = Math.floor((distance % (1000 * 60)) / 1000)?.toLocaleString(
        'en-US',
        {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }
      );

      setTimeLeft({
        days,
        hours,
        mins,
        secs,
      });

      // If the count down is finished, write some text
      if (distance < 0) {
        setTimeLeft({
          days: '00',
          hours: '00',
          mins: '00',
          secs: '00',
        });
        return false; // stop timer
      }
      return true;
    }

    const timer = setInterval(() => {
      const shouldContinue = runTimer();
      if (!shouldContinue || !promotion?.show_banner_countdown)
        clearInterval(timer);
    }, 1000);
    return () => clearInterval(timer);
  }, [
    promotion,
    promotion?.first_seen,
    promotion?.end_at,
    promotion?.end_at,
    endTime,
  ]);

  return timeLeft;
};

export { usePromotionTimer };
