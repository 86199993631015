import React, { useEffect, useContext, useState, useRef } from 'react';

import classes from './mobile-side-nav.module.css';
import HeaderWhyUsMenu from '@/Components/header/header-why-us-menu/header-why-us-menu';
import HeaderAboutMenu from '@/Components/header/header-about-menu/header-about-menu';
import HeaderDivisionsMenu from '@/Components/header/header-divisions-menu/header-divisions-menu';
import HeaderDestinationsMenu from '@/Components/header/header-destinations-menu/header-destinations-menu';
import SearchInput from '@/Components/header/search-input/search-input';
import { useRouter } from 'next/router';
import { AppLink } from '@/Components/link/link';
import getMenus from '../header/header-destinations-menu/helpers';
import { BookACallContext, GetInTouchToggleContext } from 'layouts/MainLayout';
import { useAtomValue } from 'jotai';
import { promotionsAtom } from '@/State/global/global.store';
import { HeaderNavigation } from '@/Lib/types/header-navigation';
import { useDisabledDocumentScroll } from '@/Lib/hooks/useDisabledDocumentScroll';
import { LinkButton } from '../button/link';
import { AppStoreButton } from './apple-store';
import { GooglePlayButton } from './google-play';
import { useIsGAppUserAgent } from '@/Lib/hooks/useIsGAppUserAgent';

const gworlDomainName =
  process?.env?.NEXT_PUBLIC_GWORLD_DOMAIN_NAME ||
  'gworld.globalworkandtravel.com';

type Props = {
  isOpen: boolean;
  headerHeight: number;
  navigationData: HeaderNavigation;
};

function MobileSideNav({ isOpen, headerHeight, navigationData }: Props) {
  const isGApp = useIsGAppUserAgent();
  const router = useRouter();
  const { height: bannerHeight } = useAtomValue(promotionsAtom);
  const topAnchorRef = useRef<HTMLDivElement>(null);
  const subMenuTopAnchorRef = useRef<HTMLDivElement>(null);
  const getInTouchContext = useContext(GetInTouchToggleContext);
  const bookACallContext = useContext(BookACallContext);

  const openGetInTouch = () => {
    getInTouchContext?.setIsGetInTouchOpen(true);
    // must make sure no previous tripId which could be set by
    // ask-a-question component is sent so iframe with correct url is shown
    bookACallContext?.setTripId(0);
  };

  const [subMenu, setSubMenu] = useState<{
    title: string;
    component: JSX.Element;
  } | null>();

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setSubMenu(null);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [subMenuState, setSubMenuState] = useState(false);

  const sidenavClasses = `${classes.sideNav} ${isOpen ? classes.active : ''}`;
  const sidenavStyles = {
    height: `calc(100vh - ${headerHeight}px - ${bannerHeight}px)`,
    top: `calc(${headerHeight}px + ${bannerHeight}px)`,
  };

  useEffect(() => {
    if (!isOpen) {
      topAnchorRef.current?.scrollIntoView();
    } else {
      setSubMenuState(false);
    }
  }, [isOpen]);

  useDisabledDocumentScroll({
    isDisabled: isOpen,
  });

  // const navigationData = useQuery(NAVIGATION_MENU_QUERY);
  const [regionsMenuData, setRegionsMenuData] = useState<any>();

  useEffect(() => {
    if (navigationData?.regions) {
      const result = navigationData.regions.map((r) => {
        let resgoin = { ...r };
        resgoin.destinations = resgoin.destinations.filter((d) => d.count > 0);
        return resgoin;
      });
      setRegionsMenuData(getMenus(result));
    }
  }, [navigationData]);

  const subMenuNavs = [
    {
      title: 'What',
      component: (
        <HeaderDivisionsMenu
          navigationData={navigationData}
          activeMenu="What"
        />
      ),
    },
    {
      title: 'Where',
      component: (
        <HeaderDestinationsMenu
          navigationData={regionsMenuData}
          activeMenu="Where"
        />
      ),
    },
    {
      title: 'Why',
      component: <HeaderWhyUsMenu activeMenu="Why" />,
    },
    {
      title: 'How',
      component: <HeaderAboutMenu activeMenu="How" />,
    },
  ];

  const handleSubmenuChange = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    submenu: { title: string; component: JSX.Element }
  ) => {
    e.stopPropagation();
    setSubMenu(submenu);
    subMenuTopAnchorRef.current?.scrollIntoView();
    setSubMenuState(true);
  };

  const handleSubMenuClose = () => {
    setSubMenuState(false);
  };

  return (
    <>
      <div
        tabIndex={0}
        className={`fixed text-left px-5 pt-4 pb-32 right-0 left-0 top-0 bottom-0 h-fit bg-white z-30 overflow-x-hidden outline-none ${sidenavClasses} ${
          subMenuState ? ' overflow-y-hidden' : ' overflow-y-scroll'
        }`}
        style={sidenavStyles}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="sn-top-anchor absolute top-0" ref={topAnchorRef} />
        <SearchInput classes="border-b border-solid border-grey" />
        <ul className="py-4">
          {subMenuNavs.map((s) => (
            <li key={s.title}>
              <div
                className="flex justify-between py-4 text-dark-900 "
                onClick={(e) => handleSubmenuChange(e, s)}
              >
                <h3 className="font-semibold relative">{s.title}</h3>
                <i className="icon-right" />
              </div>
            </li>
          ))}
        </ul>
        <hr />
        <div className="flex flex-col gap-5 my-5">
          <LinkButton
            text="Manage my trip"
            href={`https://${gworlDomainName}`}
            color="dark-border"
            className="!h-14"
            target={isGApp ? '_self' : '_target'}
          />
          <div className="flex gap-2.5">
            <AppStoreButton />
            <GooglePlayButton />
          </div>
        </div>
        <hr />
        <div className="py-5">
          <ul className="grid grid-cols-2 gap-x-5">
            <li>
              <AppLink
                href="/parents"
                className="py-1 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Parents
              </AppLink>
            </li>
            <li>
              <AppLink
                href="/about-us"
                className="py-1 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                About us
              </AppLink>
            </li>
            <li>
              <AppLink
                href="/partners"
                className="py-1 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Partners
              </AppLink>
            </li>
            <li>
              <AppLink
                href="/contact"
                className="py-1 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Contact us
              </AppLink>
            </li>
            <li>
              <AppLink
                href="/careers"
                className="py-1 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Careers
              </AppLink>
            </li>
            <li>
              <AppLink
                href={{
                  pathname: '/terms-and-conditions',
                  query: { tab: 'bc' },
                }}
                className="py-1 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Booking conditions
              </AppLink>
            </li>
            <li>
              <AppLink
                href="/affiliate"
                className="py-1 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Affiliates
              </AppLink>
            </li>
            <li>
              <AppLink
                href={{
                  pathname: '/terms-and-conditions',
                  query: { tab: 'pp' },
                }}
                className="py-1 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Privacy policy
              </AppLink>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`sn-sub-menu fixed top-0 left-0 w-full h-full bg-white z-40 pt-4 overflow-y-scroll  ${
          classes.sideNav
        } ${isOpen && subMenuState ? classes.active : ''}`}
        style={sidenavStyles}
      >
        <div className="absolute top-0" ref={subMenuTopAnchorRef} />
        <button
          aria-label="close sub-menu"
          className="flex flex-row items-center px-2"
          onClick={handleSubMenuClose}
        >
          <i className="icon-left mr-2" />
          <h3 className="font-semibold h-full">{subMenu && subMenu.title}</h3>
        </button>
        <div className="px-6 pb-32 bg-white">
          {subMenu && subMenu.component}
        </div>
      </div>
    </>
  );
}

export default MobileSideNav;
