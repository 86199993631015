import Head from 'next/head';
import { useQuery } from '@apollo/client';
import { CountData } from '@/Lib/types/count-data';
import { ConfigQuery } from '@/Lib/graphql/queries/config.query';

const GenericStructuredData = () => {
  const { data: configData } = useQuery<{ config: CountData }>(ConfigQuery);

  const data = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Global Work & Travel',
    logo: 'https://res.cloudinary.com/gwatco/image/upload/t_banner-sm,dpr_auto/website/static/homepage/be-more-than-a-tourist.png',
    url: 'http://www.globalworkandtravel.com',
    sameAs: [
      'https://www.facebook.com/turnmydreamintoreality',
      'https://twitter.com/thegwatco',
      'https://plus.google.com/+Globalworkandtravel',
      'https://instagram.com/globalworkandtravel',
      'https://www.youtube.com/user/gwatsup',
      'https://www.pinterest.com/thegwatco',
      'https://www.linkedin.com/company/the-global-work-&-travel-co.',
      'http://globalworkandtravel.tumblr.com',
    ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+61 1300 40 11 90',
        contactType: 'Customer Service',
        contactOption: 'TollFree',
        areaServed: 'AU',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+64 09 925 0387',
        contactType: 'Customer Service',
        contactOption: 'TollFree',
        areaServed: 'NZ',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+44 0203 002 2138',
        contactType: 'Customer Service',
        contactOption: 'TollFree',
        areaServed: 'GB',
      },
      {
        '@type': 'ContactPoint',
        telephone: ' +1 877 744 5622',
        contactType: 'Customer Service',
        contactOption: 'TollFree',
        areaServed: ['US', 'CA'],
      },
    ],
    aggregateRating: {
      '@type': 'AggregateRating',
      worstRating: '1',
      bestRating: '5',
      ratingValue: `${configData?.config.reviews.rating || '4.7'}`,
      ratingCount: `${configData?.config.reviews.count || '11153'}`,
    },
  };
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      ></script>
    </Head>
  );
};

export { GenericStructuredData };
