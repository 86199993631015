import { Notifications } from '@/Lib/types/social-proof';
import { NotificationStorageHelper } from './notification-storage.helper';

class NotificationHelper {
  static removeAlreadySeenEntries(data: Notifications): Notifications {
    const anchors = NotificationStorageHelper.get();
    if (!anchors || !anchors.first || !anchors.last) return data;

    const firstIdx = data.findIndex((i) => i.hash === anchors.first);
    const lastIdx = data.findIndex((i) => i.hash === anchors.last);

    if (firstIdx === -1 || lastIdx === -1) return data;

    return data.filter((_, idx) => idx < firstIdx || lastIdx < idx);
  }
}

export { NotificationHelper };
