import { BannerStyles, Promotion } from '@/Lib/types/trip';
import { usePromotionTimer } from './usePromotionTimer';

type Props = {
  promotion: Promotion;
  mergedStyles: BannerStyles;
};

type TTimeLeftKeys = 'days' | 'hours' | 'mins' | 'secs';

const PromotionBannerTimer = ({ promotion, mergedStyles }: Props) => {
  const timeLeft = usePromotionTimer({ promotion });
  const shouldTimerBeShown =
    promotion?.show_banner_countdown &&
    promotion?.first_seen &&
    promotion?.end_at;

  const formatTime = (time: string) => {
    switch (time) {
      case 'days':
        return 'd';
        break;
      case 'hours':
        return 'h';
        break;
      case 'mins':
        return 'm';
        break;
      case 'secs':
        return 's';
        break;

      default:
        break;
    }
  };

  const checkShow = (timeElement: string, time: string) => {
    if (
      (timeElement === 'days' && time === '00') ||
      (timeLeft['days'] === '00' && timeElement === 'hours' && time === '0')
    ) {
      return false;
    }

    return true;
  };

  return shouldTimerBeShown ? (
    <>
      <div className="md:my-1 flex sm:ml-1.5">
        {Object.keys(timeLeft).map((timeElement, index) => (
          <div key={index} className="mx-1 ">
            {checkShow(timeElement, timeLeft[timeElement as TTimeLeftKeys]) && (
              <div
                className="text-xl md:text-2.5xl font-bold text-center"
                style={{ color: mergedStyles?.countdown?.color }}
              >
                {timeLeft[timeElement as TTimeLeftKeys]}
                {formatTime(timeElement)}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  ) : null;
};

export { PromotionBannerTimer };
