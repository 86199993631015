import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { useMemo } from 'react';
import { mergeDeep } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';

let apolloClient: ApolloClient<any>;

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (true) {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}, Query: ${
            operation.operationName
          }, Variables: ${JSON.stringify(operation?.variables || {}, null, 2)}`
        )
      );
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);

    console.log('[Query details]:', {
      name: operation.operationName,
      variables: JSON.stringify(operation?.variables || {}, null, 2),
    });

    const message = graphQLErrors && graphQLErrors[0].message;

    if (message) {
      Sentry.captureException(`Apollo query error: ${message}`, {
        tags: {
          is_server: typeof window === 'undefined',
          error_type: networkError ? 'Network error' : 'GraphQL error',
          query_name: operation.operationName,
        },
        extra: {
          variables: JSON.stringify(operation?.variables || null),
        },
      });
    }
  }
});

function createApolloClient() {
  const httpLink = new HttpLink({
    uri:
      `https://${process.env.NEXT_PUBLIC_WEBSITE_API_URL}/` ||
      'https://sls.globalworkandtravel.com/',
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    link: from([errorLink, httpLink]),
    connectToDevTools: true,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            config: {
              merge(existing, incoming) {
                return mergeDeep(existing, incoming);
              },
            },
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore(mergeDeep(existingCache, initialState));
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState: any) {
  return useMemo(() => initializeApollo(initialState), [initialState]);
}
