import { TCurrency } from '../types/currency';
import { Price } from '../types/trip';

type TGetFullPriceArgs = {
  currency: TCurrency;
  basePrice: Price;
  durationPrice?: Price;
  availabilityPrice?: Price;
  dynamicPrice: Price | null;
  discount: number;
};

type TGetFullPriceResult = {
  price: number;
  discountedPrice: number;
};

class PriceHelper {
  private static calculateModifiedPrice(price: Price, modifer: Price): Price {
    const newPrice: Price = {
      AUD: price.AUD + modifer.AUD,
      CAD: price.CAD + modifer.CAD,
      USD: price.USD + modifer.USD,
      GBP: price.GBP + modifer.GBP,
      EUR: price.EUR + modifer.EUR,
      NZD: price.NZD + modifer.NZD,
    };
    return newPrice;
  }

  static getFullPrice({
    currency,
    basePrice,
    durationPrice,
    availabilityPrice,
    dynamicPrice,
    discount,
  }: TGetFullPriceArgs): TGetFullPriceResult {
    let price = basePrice;

    if (durationPrice && durationPrice[currency]) {
      price = durationPrice;
    }

    if (availabilityPrice && availabilityPrice[currency]) {
      price = availabilityPrice;
    }

    if (dynamicPrice) {
      const newPrice = this.calculateModifiedPrice(price, dynamicPrice);
      price = newPrice;
    }

    return {
      price: price[currency],
      discountedPrice: price[currency] - discount,
    };
  }
}

export { PriceHelper };
