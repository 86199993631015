import { useEffect, useState } from 'react';
import { SmartStorage } from '../services/smart-storage/smart-storage';
import { StorageKeys } from '../services/storage-keys';
import { compareProductAtom } from '@/State/global/global.store';
import { useAtom } from 'jotai';

const useCompare = () => {
  const comparesStore = SmartStorage.createWithLocalStorage().over(
    StorageKeys.ProductCompares
  );

  const [isCompareActive, setIsCompareActive] = useState(false);
  const [compareProductList, setCompareProductList] =
    useAtom(compareProductAtom);
  const [loadingCompare, setLoadingCompare] = useState(false);

  const toggleCompare = (
    tripId: number,
    destination: string,
    rate: string,
    bannerImg: string,
    tripName: string,
    division: string
  ) => {
    let coms = compareProductList;

    // if found in compares, must remove
    if (
      coms &&
      coms.length > 0 &&
      coms.find((item) => item.tripId === tripId)
    ) {
      const updatedComs = coms.filter((item) => item.tripId !== tripId);
      setCompareProductList(updatedComs);
      setLoadingCompare(false);
      return;
    } else {
      if (coms) {
        coms = [
          ...coms,

          {
            tripId,
            destination,
            rate,
            bannerImg,
            tripName,
            division,
          },
        ];
        setCompareProductList(coms);
      } else {
        setCompareProductList([
          {
            tripId,
            destination,
            rate,
            bannerImg,
            tripName,
            division,
          },
        ]);
      }
    }
  };

  const RemoveAllCompares = () => {
    setCompareProductList(null);
  };

  useEffect(() => {}, []);
  return {
    toggleCompare,
    RemoveAllCompares,
    loadingCompare,
  };
};
export { useCompare };
