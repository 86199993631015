import { useDevice } from '@/Lib/hooks/useDevice';
import { BookACallContext, GetInTouchToggleContext } from 'layouts/MainLayout';
import { useContext } from 'react';

type Props = {
  phone: string;
};

const PhoneBox = ({ phone }: Props) => {
  const { isMD } = useDevice();
  const getInTouchContext = useContext(GetInTouchToggleContext);
  const bookACallContext = useContext(BookACallContext);

  const clickHandler = () => {
    if (!isMD) {
      window.location.href = 'tel:' + phone;
      return;
    }

    getInTouchContext?.setIsGetInTouchOpen(true);
    // must make sure no previous tripId which could be set by
    // ask-a-question component is sent so iframe with correct url is shown
    bookACallContext?.setTripId(0);
  };

  return (
    <div className="flex items-center gap-2.5" onClick={clickHandler}>
      <div className="hidden lg:block border-r border-light-800 self-stretch w-px my-1"></div>
      <div className="hidden lg:block font-semibold text-3xs text-dark-500">
        Speak to an expert now
      </div>
      <div className="relative py-1.5 px-3.5 lg:px-4 bg-teal-600 bg-opacity-30 rounded-full cursor-pointer">
        <div className="flex flex-col items-center">
          <div className="text-sm lg:text-base font-semibold text-dark-900">
            {phone}
          </div>
          <div className="font-semibold text-3xs text-dark-500 block lg:hidden">
            Speak to an expert now
          </div>
        </div>
        <div className="absolute top-0 left-0 bg-light-600 rounded-full w-3 h-3 flex justify-center items-center">
          <div className="bg-green-900 rounded-full w-1.5 h-1.5"></div>
        </div>
      </div>
    </div>
  );
};

export { PhoneBox };
