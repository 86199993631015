import {
  flagsAreLoadingAtom,
  userIdentifiedStatusForAnalyticsAtom,
} from '@/State/global/global.store';
import { useFlagsmith } from 'flagsmith/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useDevice } from '../useDevice';

export const useAppFlags = () => {
  const flagsmith = useFlagsmith();
  const { isMD } = useDevice();
  const [traitsQueue, setTraitsQueue] = useState({});
  const isUserIdentified = useAtomValue(userIdentifiedStatusForAnalyticsAtom);
  const setFlagsAreLoading = useSetAtom(flagsAreLoadingAtom);

  const setTraits = useCallback(
    (traits: Object) => {
      if (!isUserIdentified) {
        setTraitsQueue({ ...traitsQueue, ...traits });
      } else {
        flagsmith.setTraits(traits as any);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flagsmith, isUserIdentified, setFlagsAreLoading]
  );

  useEffect(() => {
    setTraitsQueue({
      platform: !isMD ? 'mobile' : 'desktop',
    });
  }, [isMD]);

  useEffect(() => {
    if (isUserIdentified) {
      flagsmith.setTraits(traitsQueue as any);
      setFlagsAreLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserIdentified]);

  return {
    setTraits,
  };
};
