import { HeaderNavigationRegion } from '@/Lib/types/header-navigation';

function getMenus(regions: HeaderNavigationRegion[]) {
  const [americaMenu, otherMenus] = mergeAmericas(regions);

  // Sort america list alphabetically
  americaMenu.submenu = americaMenu.submenu.sort((a: any, b: any) =>
    a.name.localeCompare(b.name)
  );
  return [americaMenu, ...otherMenus];
}

function mergeAmericas(regions: HeaderNavigationRegion[]) {
  return regions?.reduce(
    (arrays: any, region: HeaderNavigationRegion) => {
      const [americas, others] = arrays;

      const destinations = region.destinations.map((destination) => ({
        name: destination.name,
        link: destination.url,
      }));

      if (region.name?.toLowerCase().indexOf('america') >= 0) {
        americas.submenu = [...americas.submenu, ...destinations];
      } else {
        others.push({
          title: region.name,
          submenu: destinations,
        });
      }

      return [americas, others];
    },
    [
      {
        title: 'Americas',
        submenu: [],
      },
      [],
    ]
  );
}
export default getMenus;
