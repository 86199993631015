import { GImage } from '../g-image';

const GooglePlayButton = () => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.globalworkandtravel.gapp"
      className="block w-full"
      target="_blank"
      rel="noreferrer"
    >
      <div className="w-full h-14 px-2.5 bg-dark-900 rounded-md flex items-center cursor-pointer ">
        <div className="flex items-center gap-1">
          <div className="w-6 h-6 m-1">
            <GImage
              path="website/icons/page/google-play.svg"
              alt="google play icon"
              disableLoadAnimation
            />
          </div>
          <div className="text-left">
            <div className="text-2xs !leading-3 !font-medium text-light-600 ">
              Get it on
            </div>
            <div className="text-sm !leading-5 font-semibold text-light-600 mt-1">
              Google Play
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};
export { GooglePlayButton };
