import { TParamBlacklists } from '@/Lib/types/query-param';

// divisions, activities, operators, destinations => search page filter
// tab => T&C
// contact => intercom
// success => global-animal-welfare-fund payment coming back from stripe
// activities, regions, countries, durations, ages, operators => segment page filter
// promo => self-explanatory
export const usedQueryParams = [
  'divisions',
  'activities',
  'operators',
  'destinations',
  'tab',
  'contact',
  'success',
  'regions',
  'countries',
  'durations',
  'ages',
  'promo',
];

export const conditionalBlacklist: TParamBlacklists = [
  {
    applicableCondition: { key: 'utm_medium', value: 'affiliate' },
    affected: [
      'utm_medium',
      'utm_source',
      'utm_campaign',
      'affiliate_id',
      'ref',
      'link_id',
    ],
  },
];
