import { useRouter } from 'next/router';
import { useEffect } from 'react';

type Props = {
  isDisabled: boolean;
  removeOnIsDisabledToggle?: boolean;
};

const useDisabledDocumentScroll = ({
  isDisabled,
  removeOnIsDisabledToggle = true,
}: Props) => {
  const router = useRouter();

  // conditional disabling
  useEffect(() => {
    if (isDisabled) {
      document.body.classList.add('overflow-hidden');
      document.documentElement.classList.add('overflow-hidden');
    }
    if (!isDisabled && removeOnIsDisabledToggle) {
      document.body.classList.remove('overflow-hidden');
      document.documentElement.classList.remove('overflow-hidden');
    }
  }, [isDisabled, removeOnIsDisabledToggle]);

  // restoring on unmount
  useEffect(() => {
    return () => {
      document.body.classList.remove('overflow-hidden');
      document.documentElement.classList.remove('overflow-hidden');
    };
  }, []);

  // restoring on path change (in case react unmount efffect
  // above doesn't work, you may say OCD-like)
  useEffect(() => {
    router.beforePopState(() => {
      document.body.classList.remove('overflow-hidden');
      document.documentElement.classList.remove('overflow-hidden');
      return true;
    });
  }, [router]);
};

export { useDisabledDocumentScroll };
