import { ParamObject, ParamValue } from '../types/query-param';
import omit from 'lodash/omit';
import { conditionalBlacklist, usedQueryParams } from 'data/used-query-params';

class QueryParam {
  private static getURLSearchParams(url: string): URLSearchParams {
    const splittedUrl = url.split('?');
    const params = splittedUrl.length > 1 ? splittedUrl[1] : '';
    return new URLSearchParams(params);
  }

  static getAll(url: string): ParamObject {
    const urlSearchParams = this.getURLSearchParams(url);
    let params: ParamObject = {};
    Array.from(urlSearchParams.keys()).forEach((k) => {
      const values = urlSearchParams.getAll(k);
      params[k] = this.transformValues(values);
    });
    return params;
  }

  static get(url: string, key: string): ParamValue {
    const urlSearchParams = this.getURLSearchParams(url);
    const values = urlSearchParams.getAll(key);
    return this.transformValues(values);
  }

  static getFirst(url: string, key: string): string | null {
    const urlSearchParams = this.getURLSearchParams(url);
    const values = urlSearchParams.getAll(key);
    const params = this.transformValues(values);

    if (typeof params === 'string') return params;
    if (params && params.length > 0) return params[0];
    return null;
  }

  static stringify(params: ParamObject): string {
    const transformed = this.transformParmasToArray(params);
    const urlSearchParams = new URLSearchParams(transformed);
    return urlSearchParams.toString();
  }

  static omitBlacklisted(existingParams: ParamObject): ParamObject {
    const filteredByUsedInWebsite = omit(existingParams, usedQueryParams);
    const filteredByBothUsedAndBlacklisted = this.filterBlacklisted(
      filteredByUsedInWebsite
    );
    return filteredByBothUsedAndBlacklisted;
  }

  private static filterBlacklisted(params: ParamObject): ParamObject {
    let keysToRemove: Set<string> = new Set();

    Object.keys(params).forEach((key) => {
      conditionalBlacklist.forEach((blacklistItem) => {
        if (
          blacklistItem.applicableCondition.key === key &&
          blacklistItem.applicableCondition.value === params[key]
        ) {
          blacklistItem.affected.forEach((itemToRemove) => {
            keysToRemove.add(itemToRemove);
          });
        }
      });
    });

    keysToRemove.forEach((key) => {
      delete params[key];
    });

    return params;
  }

  // NOTE: URLSearchParmas only accepts multiple values in form of strign[][]
  // but nextjs uses objects with arrays as their values for this. so we have
  // to bend over backwards since they employ dunning-kruger biased soy devs
  private static transformParmasToArray(params: ParamObject): string[][] {
    let array: string[][] = [];

    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (typeof value === 'string') {
        array.push([key, value]);
      } else {
        value.forEach((v) => {
          array.push([key, v]);
        });
      }
    });

    return array;
  }

  // NOTE: the reason for these shenanigans is that nextjs
  // transforms query params to simple strings if only one
  // of the same key is present. And since we use this in
  // combination with next's router, we should try to
  // mimic that behavior to prevent further mistakes
  private static transformValues(values: string[]): ParamValue {
    if (values.length === 1) {
      return values[0];
    }

    return values;
  }

  static removeAllStringifiedParams(url: string): string {
    const withoutParms = url.split('?')[0];
    const withoutFragment = withoutParms.split('#')[0];
    return withoutFragment;
  }
}

export { QueryParam };
