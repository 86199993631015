import { logOnce } from '@/Lib/log-once';

declare const geq: any;

type RetentionProductItem = {
  Name: string;
  Price: string;
  ProductID: string;
  Categories: string;
  ImageURL: string;
  URL: string;
  Brand: string;
};

export const Retention = {
  page: () => {
    if (geq) {
      geq.page();
      console.debug('Retention page called.');
    } else {
      logOnce.Error(
        'retention-page',
        'Retention page call failed. geq not found.'
      );
    }
  },

  suppress: () => {
    if (geq) {
      geq.suppress();
      console.debug('Retention suppress called.');
    } else {
      logOnce.Error(
        'retention-suppress',
        'Retention suppress call failed. geq not found.'
      );
    }
  },

  addToCart: (item: RetentionProductItem) => {
    if (geq) {
      geq.addToCart(item);
      console.debug('Retention addToCart called.', item);
    } else {
      logOnce.Error(
        'retention-add-to-cart',
        'Retention addToCart call failed. geq not found.'
      );
    }
  },

  viewedProduct: (item: RetentionProductItem) => {
    if (geq) {
      geq.event('Viewed Product Reclaim', item);
      console.debug('Retention ViewedProduct called.', item);
    } else {
      logOnce.Error(
        'retention-viewed-product',
        'Retention ViewedProduct call failed. geq not found.'
      );
    }
  },
};
