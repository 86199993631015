import React, { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { GImage } from '@/Components/g-image';
import { BookACallContext, GetInTouchToggleContext } from 'layouts/MainLayout';
import { useAtomValue } from 'jotai';
import { isInTripPageAtom } from '@/State/global/global.store';

type Props = {
  forceShowText: boolean;
};

const AvatarIcon = ({ forceShowText }: Props) => {
  const router = useRouter();
  const [isTextShown, setIsTextShown] = useState<boolean>(false);
  const getInTouchContext = useContext(GetInTouchToggleContext);
  const bookACallContext = useContext(BookACallContext);
  const isInTripPage = useAtomValue(isInTripPageAtom);

  useEffect(() => {
    if (isInTripPage) {
      setIsTextShown(true);
    } else {
      setIsTextShown(false);
    }
  }, [isInTripPage]);

  const clickHandler = () => {
    getInTouchContext?.setIsGetInTouchOpen(true);
    // must make sure no previous tripId which could be set by
    // ask-a-question component is sent so iframe with correct url is shown
    bookACallContext?.setTripId(0);
  };

  return (
    <div className="flex items-center" onClick={clickHandler}>
      <div className="h-1.4rem lg:h-5.5">
        <GImage
          path="new-website/icons/contact.svg"
          alt="contact"
          width="auto"
          height="100%"
          classes="!object-contain"
        />
      </div>
      {isTextShown ? (
        <div
          className={
            'font-semibold text-sm ml-2' +
            (forceShowText ? '' : ' hidden lg:block ')
          }
        >
          Ask a question, 24hrs Mon-Fri
        </div>
      ) : null}
    </div>
  );
};

export { AvatarIcon };
