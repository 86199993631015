import { useEffect, useState } from 'react';

export const useIsGAppUserAgent = () => {
  const [isGApp, setIsGApp] = useState(false);

  useEffect(() => {
    if (window) {
      const doesUserAgentMatchRegex =
        window.navigator.userAgent.includes('gApp');
      setIsGApp(doesUserAgentMatchRegex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isGApp;
};
