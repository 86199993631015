type Props = {
  isOpen: boolean;
};

const OpenClosedMobile = ({ isOpen }: Props) => {
  return (
    <div className="md:hidden flex-grow text-right">
      {isOpen ? (
        <div>
          <span className="text-green-900 text-2xl">•</span>{' '}
          <span className="text-light-900 font-semibold">Open</span>
        </div>
      ) : (
        <div>
          <span className="text-red-900 text-2xl">•</span>{' '}
          <span className="text-light-900 font-semibold">Closed</span>
        </div>
      )}
    </div>
  );
};

const OpenClosedDesktop = ({ isOpen }: Props) => {
  return (
    <div className="hidden md:block mt-2">
      {isOpen ? (
        <div>
          <span className="text-green-900 text-2xl">•</span>{' '}
          <span className="text-light-900 font-semibold">Open</span>
        </div>
      ) : (
        <div>
          <span className="text-red-900 text-2xl">•</span>{' '}
          <span className="text-light-900 font-semibold">Closed</span>
        </div>
      )}
    </div>
  );
};

export { OpenClosedMobile, OpenClosedDesktop };
