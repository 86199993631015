import {IStorage} from "@/Lib/services/smart-storage/IStorage";

export class SessionStorage implements IStorage {
  set(key: string, value: string) {
    if (typeof window === 'undefined') return;
    sessionStorage.setItem(key, value);
  }

  get(key: string) {
    if (typeof window === 'undefined') return null;
    return sessionStorage.getItem(key) || null;
  }

  remove(key: string) {
    if (typeof window === 'undefined') return;
    sessionStorage.removeItem(key);
  }
}
