import { ArrowRight } from '@/Assets/icons/ArrowRight';
import { GImage } from '../g-image';

const GooglePlayBtn = () => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.globalworkandtravel.gapp"
      target="_blank"
      rel="noreferrer"
    >
      <div className="w-full md:w-15rem h-16 px-5 bg-dark-900 rounded-md flex items-center cursor-pointer ">
        <div className="flex items-center gap-x-5 flex-grow ">
          <div className="w-1.875rem h-1.875rem">
            <GImage
              path="website/icons/page/google-play.svg"
              alt="google play icon"
              disableLoadAnimation
            />
          </div>
          <div className="text-left">
            <div className="text-sm !leading-3 !font-medium text-light-600 ">
              Get it on
            </div>
            <div className="text-lg !leading-6 font-bold text-light-600 mt-1">
              Google Play
            </div>
          </div>
        </div>
        <ArrowRight />
      </div>
    </a>
  );
};
export { GooglePlayBtn };
