import { UserLocation } from '@/Lib/types/location';
import { UseOfficeReturnDataType } from '@/Lib/types/office';
import { useLocation } from '../useLocation';
import { OfficeHelper } from './helper';
import { SharedPageStaticData } from '@/Lib/types/page';
import { useSharedStaticData } from '../static-data/useSharedStaticData';

export function useOffice(): UseOfficeReturnDataType {
  const { location }: { location: UserLocation } = useLocation();
  const { offices } = useSharedStaticData<SharedPageStaticData>();

  const phone = OfficeHelper.getPhone(location, offices.phoneNumbers);

  const officeName = OfficeHelper.getOfficeName(location);

  const office =
    offices?.offices?.find((o) => o.office === officeName) ??
    offices?.offices[0];

  const isOpen = OfficeHelper.isOfficeOpen(office);

  return {
    isOpen,
    phone,
    office,
  };
}
