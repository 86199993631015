import { GImage } from '../g-image';

const AppStoreButton = () => {
  return (
    <a
      href="https://apps.apple.com/us/app/global-work-travel/id1535421676?itsct=apps_box_link&itscg=30200"
      className="block w-full"
      target="_blank"
      rel="noreferrer"
    >
      <div className="w-full h-14 px-2.5 bg-dark-900 rounded-md flex items-center cursor-pointer">
        <div className="flex items-center gap-1">
          <div className="w-6 h-6 m-1">
            <GImage
              path="website/icons/page/app-store.svg"
              alt="apple app store icon"
              disableLoadAnimation
            />
          </div>
          <div className="text-left">
            <div className="text-2xs !leading-3 !font-medium text-light-600 ">
              Download on the
            </div>
            <div className="text-sm !leading-5 font-semibold text-light-600 mt-1">
              App Store
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};
export { AppStoreButton };
