import { GImage } from '../g-image';
import { useAtomValue } from 'jotai';
import { promotionsAtom } from '@/State/global/global.store';
import { CloseIcon } from '@/Assets/icons/Close';
import { BasicTrip, Promotion, TripPrice } from '@/Lib/types/trip';
import { useQuery } from '@apollo/client';
import { GET_TRIP_PRICE } from '@/Lib/graphql/queries/trip.query';
import { useCurrency } from '@/Lib/hooks/useCurrency';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { useDynamicPrice } from '@/Lib/hooks/useDynamicPrice';
import { usePrice } from '@/Lib/hooks/usePrice';
import { Price } from './price';
import { useCompare } from '@/Lib/hooks/useCompare';

type Props = {
  trip: BasicTrip;
};

const ProductCompareCard = ({ trip }: Props) => {
  const { data: tripData } = useQuery<{ trip: TripPrice }>(GET_TRIP_PRICE, {
    variables: {
      id: trip.id,
    },
  });
  const { currency } = useCurrency();
  const { toggleCompare } = useCompare();
  const { promotions: appliedPromotions, getComputedPromos } =
    useAtomValue(promotionsAtom);

  const { discount, reducedDeposit } =
    getComputedPromos && trip
      ? getComputedPromos(
          trip.promotions,
          appliedPromotions as Promotion[],
          trip,
          trip?.prices[currency],
          tripData?.trip.deposit[currency] || 0,
          currency
        )
      : { discount: 0, reducedDeposit: 0 };
  const divisionInternalName = getInternalName(trip?.division || '');
  const dynamicPrice = useDynamicPrice(divisionInternalName);
  const basePrice = trip?.prices ?? {
    AUD: 0,
    CAD: 0,
    EUR: 0,
    GBP: 0,
    NZD: 0,
    USD: 0,
  };
  const { price, discountedPrice } = usePrice({
    basePrice: basePrice,
    dynamicPrice,
    discount,
  });

  const remove = () => {
    toggleCompare(
      Number(trip.id),
      trip.destination,
      trip.reviews.rating,
      trip.bannerImg,
      trip.tripName,
      trip.division
    );
  };

  return (
    <>
      <div className="hidden md:flex h-6.5rem rounded-md border border-light-800 items-center justify-center relative">
        <div className="w-1.75rem h-1.75rem flex justify-center items-center bg-light-600 absolute -right-3 -top-3 cursor-pointer">
          <div
            onClick={() => remove()}
            className="w-6 h-6 rounded-full flex justify-center items-center bg-red-500"
          >
            <CloseIcon width={12} height={12} />
          </div>
        </div>

        <div className="flex justify-start gap-x-2.5 w-full p-5">
          <div className="h-3.75rem  min-h-3.75rem w-5rem min-w-5rem ">
            <GImage
              path={trip.bannerImg}
              transformation="trip-cards"
              alt={trip.tripName}
              height="3.75rem"
              width="5rem"
              hasLoadingBackground
              useGallery={true}
              classes="rounded-md justify-center"
            />
          </div>
          <div className="text-sm flex-grow text-dark-800 font-semibold flex items-start flex-col justify-center gap-y-1 w-full">
            <div className="mr-1">{trip.destination}</div>
            <Price
              discount={discount}
              price={price}
              discountedPrice={discountedPrice}
            />
          </div>
        </div>
      </div>
      <div className="flex md:hidden p-5 border border-light-800 items-center gap-x-2.5 rounded-md">
        <div className="h-3.75rem min-h-3.75rem w-5rem min-w-5rem">
          <GImage
            path={trip.bannerImg}
            transformation="trip-cards"
            alt={trip.tripName}
            height="3.75rem"
            width="5rem"
            hasLoadingBackground
            useGallery={true}
            classes="rounded-md justify-center"
          />
        </div>
        <div className="flex flex-col gap-y-1.25">
          <div className="flex justify-start font-semibold items-center gap-x-1 text-base !leading-6 text-dark-800">
            <div>{trip.destination}</div>
            <Price
              discount={discount}
              price={price}
              discountedPrice={discountedPrice}
            />
          </div>
          <div
            className="flex justify-start items-center gap-x-1.25"
            onClick={() => remove()}
          >
            <div className="w-3 h-3 rounded-full bg-dark-600 flex justify-center items-center">
              <CloseIcon color="#fff" width={8} height={8} />
            </div>
            <div className="text-dark-600 text-2xs !leading-4">Remove</div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ProductCompareCard };
