import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { useCurrency } from '@/Lib/hooks/useCurrency';
import { useLocation } from '@/Lib/hooks/useLocation';
import { TCurrencyObject } from '@/Lib/types/currency';
import { currencyChangeTripDataAtom } from '@/State/global/global.store';
import { currencySymbols } from 'data/currency';
import { useAtomValue } from 'jotai';

type Props = {
  currency: TCurrencyObject;
  isSelected: boolean;
};

const CurrencyButton = ({ currency, isSelected }: Props) => {
  const { location } = useLocation();
  const { currency: activeCurrency, setCurrency } = useCurrency();
  const currencyChangeTripData = useAtomValue(currencyChangeTripDataAtom);

  const clickHandler = () => {
    RudderAnalytics.currencyChange({
      country: location.country.name,
      product_id: currencyChangeTripData.id,
      product_name: currencyChangeTripData.name,
      currnet_currency: activeCurrency,
      selected_currency: currency.code,
    });

    setCurrency(currency.code);
  };

  return (
    <div
      className={
        'flex items-center justify-start gap-2.5 md:gap-4 p-4 border rounded-md cursor-pointer transition-colors' +
        (isSelected
          ? ' border-teal-900 bg-tealSuperLight'
          : ' border-light-800')
      }
      onClick={clickHandler}
    >
      <div>
        <span
          className={`fp fp-square fp-lg ${currency.country.code} min-w-2rem min-h-2rem md:min-w-2.5rem md:min-h-2.5rem rounded-full`}
        ></span>
      </div>
      <div className="flex flex-col items-start gap-1">
        <div className="text-dark-900 text-lg font-semibold leading-5">
          {currency.country.name}
        </div>
        <div className="text-dark-700 text-sm font-medium leading-4">
          {currency.code}({currencySymbols[currency.code]})
          {currency.description ? ` - ${currency.description}` : ''}
        </div>
      </div>
    </div>
  );
};

export { CurrencyButton };
