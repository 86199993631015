import {IStorage} from "@/Lib/services/smart-storage/IStorage";

export class InMemoryStorage implements IStorage {
  storage: Record<string, string>;

  constructor() {
    this.storage = {};
  }

  set(key: string, value: string) {
    this.storage[key] = value;
  }

  get(key: string) {
    return this.storage[key] || null;
  }

  remove(key: string) {
    delete this.storage[key];
  }
}