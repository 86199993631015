import { PriceHelper } from '../helpers/price';
import { Price } from '../types/trip';
import { useCurrency } from './useCurrency';

type Props = {
  basePrice: Price;
  durationPrice?: Price;
  availabilityPrice?: Price;
  dynamicPrice: Price | null;
  discount: number;
};

const usePrice = (args: Props) => {
  const { currency } = useCurrency();

  return PriceHelper.getFullPrice({ ...args, currency });
};

export { usePrice };
