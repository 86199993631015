import { gql } from '@apollo/client';

export const PROMOTION = gql`
  query PromotionsQuery($codes: [String]) {
    promotions(codes: $codes) {
      code
      type
      expires
      percent
      init
      status
      ec_banner_text
      show_banner_countdown
      banner_styles
      end_at
      auto
      tripCode
      division
      destination
      works_with
      amount {
        AUD
        CAD
        USD
        GBP
        EUR
        NZD
      }
    }
  }
`;

export const DEPOSIT = gql`
  query DepositQuery {
    trip_deposit_prices {
      AUD
      CAD
      USD
      GBP
      EUR
      NZD
    }
  }
`;
