import { FC } from 'react';
import { AppLink } from '@/Components/link/link';
import { GImage } from '@/Components/g-image';

const GlobalLogo: FC = () => {
  return (
    <AppLink
      href="/"
      className="w-30 h-10 relative text-base pointer-events-auto"
    >
      <GImage
        path="website/logo/global-work-and-travel-logo.svg"
        alt="global work and travel logo"
        classes="!object-contain select-none pointer-events-none"
        visibleByDefault
      />
    </AppLink>
  );
};

export default GlobalLogo;
