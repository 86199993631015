import { ReactNode } from 'react';
import styles from './button.module.css';
import { TButtonSize, TButtonColor } from './button.type';

export type Props = {
  text: string;
  color?: TButtonColor;
  size?: TButtonSize;
  icon?: string | ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  textClassName?: string;
};

function BaseButton({
  text,
  color = 'red',
  size = 'medium',
  icon,
  isDisabled = false,
  isLoading = false,
  className,
  textClassName,
}: Props) {
  return (
    <div
      className={
        `${styles.button} ${className}` +
        ' whitespace-nowrap rounded-md flex justify-center items-center font-semibold hover:bg-opacity-90 active:bg-opacity-80 transition-colors' +
        (color === 'red' ? ' bg-red-900' : '') +
        (color === 'teal' ? ' bg-teal-900' : '') +
        (color === 'teal-border'
          ? ' bg-transparent border border-teal-900'
          : '') +
        (color === 'dark' ? ' bg-dark-900' : '') +
        (color === 'dark-border'
          ? ' bg-transparent border border-dark-900'
          : '') +
        (color === 'light' ? ' bg-light-600' : '') +
        (color === 'light-border'
          ? ' bg-transparent border border-light-600'
          : '') +
        (color === 'yellow' ? ' bg-yellow-900' : '') +
        (size === 'medium' ? ' h-10 px-4' : '') +
        (isDisabled ? ' !bg-light-800 cursor-not-allowed opacity-50' : '') +
        (isLoading
          ? ` ${styles.loading_indicator} !bg-light-800 !text-light-800`
          : '')
      }
    >
      {icon && typeof icon !== 'string' ? icon : null}
      {text.length ? (
        <div
          className={
            `${textClassName} font-semibold transition-all` +
            (color === 'red' ? ' text-light-600' : '') +
            (color === 'teal' ? ' text-light-600' : '') +
            (color === 'teal-border' ? ' text-teal-900' : '') +
            (color === 'dark' ? ' text-light-600' : '') +
            (color === 'dark-border' ? ' text-dark-900' : '') +
            (color === 'light' ? ' text-dark-900' : '') +
            (color === 'light-border' ? ' text-light-600' : '') +
            (color === 'yellow' ? ' text-darkButton ' : '') +
            (size === 'small' ? ' text-base' : '') +
            (size === 'medium' ? ' text-lg' : '') +
            (isDisabled ? ' !text-dark-700' : '') +
            (isLoading ? ' !text-opacity-0' : '')
          }
        >
          {text}
        </div>
      ) : null}
      {icon && typeof icon === 'string' ? (
        <i
          className={
            'text-light-600' +
            (isLoading || isDisabled ? ' !text-opacity-0 ' : ' ') +
            icon
          }
        ></i>
      ) : null}
    </div>
  );
}

export { BaseButton };
