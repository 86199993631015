import React from 'react';

type Props = {
  size?: number;
  className?: string;
  stroke?: string;
};

export const InfoAppToastIcon = ({ size = 30, className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.80001C5.47084 1.80001 1.80001 5.47084 1.80001 10C1.80001 14.5292 5.47084 18.2 10 18.2C14.5292 18.2 18.2 14.5292 18.2 10C18.2 5.47084 14.5292 1.80001 10 1.80001ZM10 0.200012C4.58718 0.200012 0.200012 4.58718 0.200012 10C0.200012 15.4128 4.58718 19.8 10 19.8C15.4128 19.8 19.8 15.4128 19.8 10C19.8 4.58718 15.4128 0.200012 10 0.200012Z"
        fill="#0083A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2 10C8.2 9.5582 8.5582 9.2 9 9.2H10C10.4418 9.2 10.8 9.5582 10.8 10V15C10.8 15.4418 10.4418 15.8 10 15.8C9.5582 15.8 9.2 15.4418 9.2 15V10.8H9C8.5582 10.8 8.2 10.4418 8.2 10Z"
        fill="#0083A0"
      />
      <path
        d="M10.8 6.25002C10.8 6.82992 10.3299 7.30002 9.75 7.30002C9.1701 7.30002 8.7 6.82992 8.7 6.25002C8.7 5.67013 9.1701 5.20002 9.75 5.20002C10.3299 5.20002 10.8 5.67013 10.8 6.25002Z"
        fill="#0083A0"
      />
    </svg>
  );
};
export const ErrorAppToastIcon = ({ size = 30, className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.80001C5.47084 1.80001 1.80001 5.47084 1.80001 10C1.80001 14.5292 5.47084 18.2 10 18.2C14.5292 18.2 18.2 14.5292 18.2 10C18.2 5.47084 14.5292 1.80001 10 1.80001ZM10 0.200012C15.4128 0.200012 19.8 4.58718 19.8 10C19.8 15.4128 15.4128 19.8 10 19.8C4.58718 19.8 0.200012 15.4128 0.200012 10C0.200012 4.58718 4.58718 0.200012 10 0.200012Z"
        fill="#FE5754"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.70001C10.4418 4.70001 10.8 5.05818 10.8 5.50001V10.5C10.8 10.9418 10.4418 11.3 10 11.3C9.5582 11.3 9.2 10.9418 9.2 10.5V5.50001C9.2 5.05818 9.5582 4.70001 10 4.70001Z"
        fill="#FE5754"
      />
      <path
        d="M11.05 14.25C11.05 14.8299 10.5799 15.3 10 15.3C9.4201 15.3 8.95 14.8299 8.95 14.25C8.95 13.6701 9.4201 13.2 10 13.2C10.5799 13.2 11.05 13.6701 11.05 14.25Z"
        fill="#FE5754"
      />
    </svg>
  );
};

export const SuccessAppToastIcon = ({
  size = 30,
  className,
  stroke = '#1AE68C',
}: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7 10L9.25 12.25L13 8.5M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke={stroke}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const WarningAppToastIcon = ({ size = 30, className }: Props) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6.58002C11.4418 6.58002 11.8 6.93819 11.8 7.38002V11.12C11.8 11.5618 11.4418 11.92 11 11.92C10.5582 11.92 10.2 11.5618 10.2 11.12V7.38002C10.2 6.93819 10.5582 6.58002 11 6.58002Z"
        fill="#FFC649"
      />
      <path
        d="M12.05 14.37C12.05 14.9499 11.5799 15.42 11 15.42C10.4201 15.42 9.95001 14.9499 9.95001 14.37C9.95001 13.7901 10.4201 13.32 11 13.32C11.5799 13.32 12.05 13.7901 12.05 14.37Z"
        fill="#FFC649"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3343 2.53569C11.7443 1.50246 10.2557 1.50246 9.6657 2.53569L2.0066 15.9399C1.42069 16.9644 2.16019 18.24 3.34099 18.24H18.659C19.8384 18.24 20.5788 16.9649 19.9934 15.9399L12.3343 2.53569ZM13.7237 1.74234C12.5197 -0.366426 9.4803 -0.366426 8.27626 1.74234L0.617532 15.1459C-0.578286 17.2373 0.931892 19.84 3.34099 19.84H18.659C21.0675 19.84 22.5771 17.2372 21.3826 15.1462L13.7237 1.74234Z"
        fill="#FFC649"
      />
    </svg>
  );
};
export const DefaultAppToastIcon = ({ size = 30, className }: Props) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3247 2.00909L12.4512 4.65607C12.3183 4.74695 12.1609 4.79557 11.9999 4.79557H4.99896C3.22986 4.79557 1.79629 6.22914 1.79629 7.99823V8.9956C1.79629 10.7651 3.23025 12.1993 4.99796 12.1993L11.9991 12.2013C12.1601 12.2014 12.3174 12.25 12.4503 12.3408L16.3249 14.9889C17.1211 15.5338 18.2038 14.9635 18.2038 13.9977V3.00115C18.2038 2.03558 17.1222 1.46457 16.3247 2.00909ZM15.4223 0.687841C17.2818 -0.582199 19.8038 0.749452 19.8038 3.00115V13.9977C19.8038 16.2493 17.2806 17.5816 15.4217 16.3097L11.7515 13.8012L4.99796 13.7993C2.34592 13.7992 0.196289 11.6479 0.196289 8.9956V7.99823C0.196289 5.34548 2.34621 3.19557 4.99896 3.19557H11.7526L15.4223 0.687841Z"
        fill="#575B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0009 3.19757C12.4427 3.19757 12.8009 3.55574 12.8009 3.99757V13.0013C12.8009 13.4431 12.4427 13.8013 12.0009 13.8013C11.559 13.8013 11.2009 13.4431 11.2009 13.0013V3.99757C11.2009 3.55574 11.559 3.19757 12.0009 3.19757Z"
        fill="#575B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.58819 12.2569C7.99932 12.0951 8.4638 12.2972 8.6256 12.7083L9.615 15.2224C10.2862 16.9298 9.0451 18.8034 7.19787 18.8034C6.1312 18.8034 5.17621 18.1478 4.78345 17.1557L3.25382 13.2961C3.09103 12.8853 3.29204 12.4204 3.70279 12.2576C4.11353 12.0948 4.57847 12.2958 4.74126 12.7066L6.2709 16.5662C6.42448 16.9543 6.79409 17.2034 7.19787 17.2034C7.89361 17.2034 8.3931 16.4879 8.1261 15.8083L7.13673 13.2943C6.97493 12.8832 7.17705 12.4187 7.58819 12.2569Z"
        fill="#575B63"
      />
    </svg>
  );
};

export const CloseAppToastIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.46445 11.5356L11.5355 4.46449"
        stroke="#575B63"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5355 11.5355L4.46445 4.46444"
        stroke="#575B63"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
