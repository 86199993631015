import { Promotion } from '@/Lib/types/trip';

interface PromoTransformer {
  transform: () => Promotion[];
}

/**
 * A convenience class used to validate conditions not relating
 * to a trip. This could include multiple_type condition ect.
 */
class ConditionsTransformer implements PromoTransformer {
  promotions: (Promotion | number)[];

  constructor(promotions: Promotion[]) {
    this.promotions = promotions;
  }

  transform(): Promotion[] {
    this.promotions = this.multiple_type();
    this.promotions = this.works_with();
    return this.promotions as Promotion[];
  }

  /**
   * Function to remove promotions where a promo of the same type exists and
   * the multiple_type condition is falsy. Promotions with the largest index
   * take the highest priority.
   * @returns { Promotion[] } - List of transformed promotions.
   */
  multiple_type(): Promotion[] {
    if (this.promotions.length < 1) {
      return this.promotions as Promotion[];
    } else {
      // For each promotion, check if there is any previous with the same
      // type, if there is ensure multiple type on both, else the later
      // takes priority.
      for (let i = 1; i < this.promotions.length; i++) {
        const p1 = this.promotions[i];
        for (let j = 0; j < i; j++) {
          const p2 = this.promotions[j];
          // If both haven't been invalidated.
          if (typeof p1 !== 'number' && typeof p2 !== 'number') {
            // If they are the same promo type, check they are valid
            if (p1.type === p2.type) {
              // Check they both have multiple typ
              if (p1.multiple_type && p2.multiple_type) {
                continue;
              } else {
                if ((this.promotions[i] as Promotion).auto) {
                  this.promotions[i] = -1;
                } else {
                  this.promotions[j] = -1;
                }
              }
            }
          }
        }
      }
      // Return only valid (elements that have not been set to -1)
      return this.promotions.filter(
        (p) => typeof p !== 'number'
      ) as Promotion[];
    }
  }

  /**
   * Function that ensures all promotions meet the works_with condition.
   * Once a promotion that has a works_with condition, it is locked out
   * to only codes that exist in that works with array. If a new works_with
   * condition enters, this then takes priority.
   * @returns { Promotion[] } - Promotions that are valid with the works_with condition.
   */
  works_with(): Promotion[] {
    if (this.promotions.length < 1) {
      return this.promotions as Promotion[];
    } else {
      /**
       * Filters out valid promotions that works_with the current promo
       * @param { Promotion } current_promo - The current promotion
       * @param { Promotion[] } existing_promotions - The existing promotions that are currently valid
       */
      const filter_valid = (
        current_promo: Promotion,
        existing_promotions: Promotion[]
      ): Promotion[] => {
        const works_with = current_promo.works_with
          .split(',')
          .map((p) => p.trim());
        return existing_promotions.filter((p) => {
          return (
            works_with.indexOf(p.code) > -1 || works_with.indexOf(p.type) > -1
          );
        });
      };

      /**
       * Checks if the current_promo is valid with the existing promotion anchors
       * @param { Promotion } current_promo - The current promotion
       * @param { Promotion[] } existing_promotions - The existing promotions that are currently valid
       */
      const valid_with_anchors = (
        current_promo: Promotion,
        existing_promotions: Promotion[]
      ): boolean => {
        let valid = true;
        // Anchors are considered promotions that have a works with field.
        const anchors = existing_promotions.filter((p) => !!p.works_with);
        anchors.forEach((a) => {
          const works_with = a.works_with.split(',').map((p) => p.trim());
          valid =
            works_with.indexOf(current_promo.code) > -1 ||
            works_with.indexOf(current_promo.type) > -1;
        });
        return valid;
      };

      let results: Promotion[] = [];
      for (let i = 0; i < this.promotions.length; i++) {
        const promo = this.promotions[i] as Promotion;

        // If works with, filters valid and ensures compatibility with anchors.
        if (promo.works_with) {
          results = filter_valid(promo, results);
          if (valid_with_anchors(promo, results)) {
            results.push(promo);
          }
        } else {
          // Else just ensures compatibility with anchors
          if (valid_with_anchors(promo, results)) {
            results.push(promo);
          }
        }
      }
      return results;
    }
  }
}

export { ConditionsTransformer };
