import { TCurrency } from '@/Lib/types/currency';
import { Promotion } from '@/Lib/types/trip';

export class Promotions {
  public valid: boolean = false;
  public discount: number = 0;
  public reducedDeposit: number = 0;
  public items: string[] = [];
  public promoLink: string = '';
  public validPromotions: Promotion[] = [];

  constructor(promotions: Promotion[], private currency: TCurrency) {
    this.isValid(promotions);
  }

  public getDiscount(tripPrice: number) {
    this.discount = Math.ceil(
      this.getPromotionTypeValue('discount', tripPrice)
    );
  }

  public getReducedDeposit(deposit: number) {
    this.reducedDeposit = this.getPromotionTypeValue(
      'reduced_deposit',
      deposit
    );
  }

  public getItems(tripPrice: number) {
    this.items = this.validPromotions.reduce((acc, promotion) => {
      if (promotion.item) {
        acc.push(promotion.item);
      } else if (promotion.type === 'flight_voucher') {
        const flightVoucherAmount = this.calculatePromotionValue(
          promotion,
          tripPrice
        );
        acc.push(`${this.currency}${flightVoucherAmount} Flight Voucher`);
      } else if (promotion.type === 'brand_voucher') {
        const brandVoucherAmount = this.calculatePromotionValue(
          promotion,
          tripPrice
        );
        acc.push(`${this.currency}${brandVoucherAmount} Voucher`);
      }

      return acc;
    }, [] as string[]);
  }

  private getPromotionTypeValue(type: string, amount: number): number {
    const promotion = this.validPromotions.find((promo) => promo.type === type);
    if (this.valid && promotion) {
      return this.calculatePromotionValue(promotion, amount);
    } else {
      return 0;
    }
  }

  private calculatePromotionValue(promotion: Promotion, amount: number) {
    if (promotion.percent) {
      return amount / promotion.percent;
    } else if (promotion.amount && promotion.amount[this.currency]) {
      return promotion.amount[this.currency];
    } else {
      return 0;
    }
  }

  private isValid(promotions: Promotion[]) {
    if (promotions && promotions.length) {
      this.validPromotions = this.toValidPromotions(promotions);
      this.promoLink = this.validPromotions.length
        ? `&promo=${this.validPromotions
            .filter((promo) => promo.amount)
            .map((promo) => promo.code)
            .join(',')}`
        : '';
      this.valid = this.validPromotions.length ? true : false;
    }
  }

  private toValidPromotions(promotions: Promotion[]) {
    return promotions.reduce((acc: Promotion[], promo: Promotion) => {
      if (promo && this.validDate(promo) && this.uniqueType(promo, acc)) {
        if (promo.amount) {
          if (promo.amount[this.currency]) {
            acc.push(promo);
          }
        } else {
          acc.push(promo);
        }
      }
      return acc;
    }, []);
  }

  private uniqueType(promo: Promotion, validPromotions: Promotion[]) {
    return validPromotions.every(
      (validPromotions) => validPromotions.type !== promo.type
    );
  }

  private validDate(promotion: Promotion) {
    const offset = -(new Date().getTimezoneOffset() * 60 * 1000);
    const currentTime = new Date(new Date().getTime() + (offset || 0));

    const expiryTime = new Date(promotion.expires * 1000 + (offset || 0));
    const notExpired = expiryTime > currentTime;

    if (!promotion.init) {
      return notExpired;
    }

    const initTime = new Date(promotion.init * 1000);

    const promotionStarted = currentTime > initTime;
    return notExpired && promotionStarted;
  }
}
