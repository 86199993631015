import { GImage } from '../../g-image';
import { AppLink } from '../../link/link';
import dayjs from 'dayjs';
import { useLocation } from '@/Lib/hooks/useLocation';

const FooterBottomOld = () => {
  const currentYear = dayjs().year();
  const { location } = useLocation();
  const logos =
    location.continent === 'Americas'
      ? ['consumer-protection-bc']
      : location.continent === 'Europe'
      ? ['atol', 'abta']
      : [];

  return (
    <div className="py-5 text-dark-800 w-full center items-center justify-center text-sm leading-5">
      <div className="flex justify-center flex-col items-center md:flex-row gap-1">
        <span>Copyright © {currentYear} The Global Work & Travel Co.</span>
        <span>All rights reserved.</span>
      </div>
      {logos.length !== 0 ? (
        <AppLink href="/financial-security">
          <div className="flex h-10 mt-5 justify-center ">
            {logos.map((logo, index) => (
              <div className="h-full mx-2.5" key={index}>
                <GImage
                  width="auto"
                  height="100%"
                  path={`website/icons/footer/${logo}.svg`}
                  alt={`${logo} logo`}
                  classes="!object-contain"
                />
              </div>
            ))}
          </div>
        </AppLink>
      ) : null}
    </div>
  );
};

export { FooterBottomOld };
