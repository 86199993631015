import 'flagpack/dist/flagpack.css';
import { allCurrencies, currencySymbols } from 'data/currency';
import { useEffect, useState } from 'react';
import { CurrencyModal } from './currency-modal';
import { useCurrency } from '@/Lib/hooks/useCurrency';

const FooterCurrency = () => {
  const { currency } = useCurrency();
  const selectedCurrency = allCurrencies.find((c) => c.code === currency);
  const countryName = selectedCurrency?.country.name ?? 'Australia';
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [countryClassName, setCountryClassName] = useState<string>('au');

  useEffect(() => {
    const countryFlagClass =
      selectedCurrency?.country.code.toLowerCase() ?? 'au';
    setCountryClassName(countryFlagClass);
  }, [selectedCurrency?.country.code]);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div
        className="md:flex-1 flex items-center justify-between mx-5 md:mx-0 text-base gap-2.5 cursor-pointer"
        onClick={openModal}
      >
        <span
          className={
            'fp fp-square fp-lg min-w-2rem min-h-2rem rounded-full ' +
            countryClassName
          }
        ></span>
        <div className="flex-grow text-dark-900 font-medium leading-5">
          {countryName} - {currency}({currencySymbols[currency]})
        </div>
        <i className="md:hidden icon-right text-pureblack "></i>
      </div>
      <CurrencyModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export { FooterCurrency };
