import { ReactElement } from 'react';
import { GImage } from '../g-image';

type Props = {
  onClick?: () => void;
  title: string;
  description: ReactElement;
  image: {
    path: string;
    alt: string;
    width: string;
    height: string;
    styles?: any;
  };
  status?: {
    mobile?: ReactElement;
    desktop: ReactElement;
  };
  button: ReactElement;
};

const Card = ({
  onClick,
  description,
  title,
  image,
  status,
  button,
}: Props) => {
  return (
    <div className="group cursor-pointer min-w-13" onClick={onClick}>
      <div className="flex flex-col h-full md:items-center">
        <div className="flex justify-between items-center mb-0.5">
          <div className="flex md:flex-col items-center">
            <div className="w-9 h-9 md:w-20 md:h-20 md:mb-2.5 flex justify-center items-center group-hover:scale-125 transition-transform duration-500">
              <GImage
                width={image.width}
                height={image.height}
                path={image.path}
                alt={image.alt}
                styles={image.styles}
              />
            </div>
            <h4 className="text-base leading-5 text-teal-800 font-bold ml-2.5 md:m-0">
              {title}
            </h4>
          </div>
          {status?.mobile}
        </div>

        <div className="text-sm md:text-center text-dark-700 leading-4.5">
          {description}
        </div>
        {status?.desktop}
        {button}
      </div>
    </div>
  );
};

export { Card };
