import {IKeySpecificStorage, IStorage} from "@/Lib/services/smart-storage/IStorage";
import {SessionStorage} from "@/Lib/services/smart-storage/session-storage";
import {InMemoryStorage} from "@/Lib/services/smart-storage/in-memory-storage";
import {LocalStorage} from "@/Lib/services/smart-storage/local-storage";

export class SmartStorage implements IStorage {
  storage: IStorage;

  constructor(primaryStorage: IStorage, fallbackStorage: IStorage) {
    try {
      const testKey = 'test';
      primaryStorage.set(testKey, testKey);
      primaryStorage.remove(testKey);

      this.storage = primaryStorage;
    } catch {
      this.storage = fallbackStorage;
    }
  }

  set(key: string, value: string) {
    this.storage.set(key, value);
  }

  get(key: string) {
    return this.storage.get(key);
  }

  remove(key: string) {
    this.storage.remove(key);
  }

  over(key: string): KeySpecificSmartStorage {
    return new KeySpecificSmartStorage(this, key);
  }

  static createWithSessionStorage(): SmartStorage {
    return new SmartStorage(new SessionStorage(), new InMemoryStorage());
  }

  static createWithLocalStorage(): SmartStorage {
    return new SmartStorage(new LocalStorage(), new InMemoryStorage());
  }
}

class KeySpecificSmartStorage implements IKeySpecificStorage {
  storage: SmartStorage;
  key: string;

  constructor(storage: SmartStorage, key: string) {
    this.storage = storage;
    this.key = key;
  }

  set(value: string) {
    this.storage.set(this.key, value);
  }

  get() {
    return this.storage.get(this.key);
  }

  remove() {
    this.storage.remove(this.key);
  }
}