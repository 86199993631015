import { AppLink } from '@/Components/link/link';
import { FC } from 'react';

type props = {
  navigationData: any;
  activeMenu: string | null;
};

const HeaderDestinationsMenu: FC<props> = ({ navigationData, activeMenu }) => {
  const isShown = activeMenu && activeMenu === 'Where';

  return (
    <ul
      className={
        'nav-menu-fade-in text-left max-w-6.5xl m-auto xs:justify-items-start justify-items-stretch xs:px-2 sm:px-8 lg:max-w-4xl xl:max-w-4.5xl xl:px-4 grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-6 py-8 font-normal text-black' +
        (isShown ? ' block' : ' hidden')
      }
    >
      {navigationData &&
        navigationData
          .filter((d: any) => d.title !== 'Remote')
          .map((menuItem: any) => (
            <li
              key={menuItem.title}
              className="max-w-md md:max-w-50 lg:w-30 mx-5 md:mx-0 text-dark-900 "
              style={{ gridRowEnd: `span ${menuItem.submenu.length + 2}` }}
            >
              <h3 className="font-semibold text-lg border-b border-black mb-4 pb-2  border-solid">
                {menuItem.title}
              </h3>
              <ul>
                {menuItem.submenu?.map((submenu: any) => (
                  <li key={submenu.link} className="my-5 text-dark-700">
                    <AppLink href={submenu.link}>{submenu.name}</AppLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
    </ul>
  );
};

export default HeaderDestinationsMenu;
