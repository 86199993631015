import { ReactNode, createContext } from 'react';

type Props<T> = {
  data: T;
  children: ReactNode;
};

export const SharedDataContext = createContext<any>(null);

const SharedStaticDataProvider = <T,>({ data, children }: Props<T>) => {
  return (
    <SharedDataContext.Provider value={data}>
      {children}
    </SharedDataContext.Provider>
  );
};

export { SharedStaticDataProvider };
