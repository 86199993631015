import { euroCountries } from '@/Data/currency';
import { TCurrency } from '../types/currency';
import { UserLocation } from '../types/location';

export const getCurrencyByLocation = (location: UserLocation): TCurrency => {
  if (location.country.code === 'GB') return 'GBP';
  if (location.country.code === 'GG') return 'GBP'; // Guernsey
  if (location.country.code === 'NZ') return 'NZD';
  if (location.country.code === 'AU') return 'AUD';
  if (location.country.code === 'CA') return 'CAD';

  if (location.region === 'South West Asia') return 'EUR';
  if (location.continent === 'Europe') return 'EUR';
  if (location.continent === 'Africa') return 'EUR';

  if (location.continent === 'Americas') return 'USD';

  if (location.continent === 'Asia') return 'AUD';
  if (location.continent === 'Oceania') return 'AUD';

  return 'USD';
};

export const getCurrencyIsChangable = (location: UserLocation): boolean => {
  if (location.country.code === 'AU') return false;
  if (location.country.code === 'CA') return false;
  if (location.country.code === 'GB') return false;
  if (location.country.code === 'NZ') return false;
  if (location.country.code === 'US') return false;
  if (euroCountries.includes(location.country.code)) return false;

  return true;
};
