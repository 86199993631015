import { Modal } from '@/Components/modals/new-modal/modal';
import { allCurrencies } from 'data/currency';
import { Dispatch, SetStateAction } from 'react';
import { CurrencyButton } from './currency-button';
import MapSvg from '@/Assets/icons/map.svg';
import NextImage from 'next/image';
import { useCurrency } from '@/Lib/hooks/useCurrency';
import { GImage } from '@/Components/g-image';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const CurrencyModal = ({ isOpen, setIsOpen }: Props) => {
  const { currency } = useCurrency();
  const close = () => setIsOpen(false);

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title="Select currency and region"
      cssClasses="md:!max-w-40rem"
    >
      <div className="hidden md:block mb-10 w-32.5rem mx-auto">
        <GImage
          path="website/static/footer/map"
          alt="illustration of the world map"
        />
      </div>
      <div
        className="flex flex-col md:grid md:grid-cols-2 gap-2.5 md:gap-5 m-4 md:m-10"
        onClick={close}
      >
        {allCurrencies.map((c) => (
          <CurrencyButton
            key={c.code}
            currency={c}
            isSelected={c.code === currency}
          />
        ))}
      </div>
    </Modal>
  );
};

export { CurrencyModal };
