import { TCurrency } from '@/Lib/types/currency';
import {
  BasicTrip,
  Promotion,
  RecentDiscoveriesTrip,
  Trip,
} from '@/Lib/types/trip';
import { fetchAutoPromotions, fetchPromotionByCode } from './promotions.query';
import { ConditionsTransformer } from './transformer';
import { ExpiryValidator, TripValidator } from './validator';

export function getCurrentTimezoneOffsetInSeconds(): number {
  return -(new Date().getTimezoneOffset() * 60);
}

type GetCurrentTimeInSecondsProps = {
  isUTC: boolean;
};

export function getCurrentTimeInSeconds({
  isUTC = false,
}: GetCurrentTimeInSecondsProps): number {
  const timezoneOffset = isUTC ? getCurrentTimezoneOffsetInSeconds() : 0;
  return Math.floor(new Date().getTime() / 1000) + timezoneOffset;
}

export function getBannerQualifiedPromotion(
  promotions: Promotion[],
  currency: TCurrency
): Promotion | null {
  const qualifiedBannerPromotions = promotions.filter(
    (promotion) => promotion && promotion && promotion.ec_banner_text
  );
  return qualifiedBannerPromotions.length ? qualifiedBannerPromotions[0] : null;
}

export function filterExpiredPromotions(
  promotions: Promotion[],
  now: number,
  currency: TCurrency
): Promotion[] {
  return promotions.filter((promotion) => {
    const notExpired = promotion.expires - now > 0;
    const hasStarted = now - promotion.init > 0;
    return (
      promotion.ec_banner_text && promotion.status && notExpired && hasStarted
    );
  });
}

export function addTimestampsToPromotions(
  promotions: Promotion[],
  now: number
): Promotion[] {
  return promotions.map((p) => {
    return {
      ...p,
      ...{
        first_seen: now,
        last_seen: now,
        end_at: Math.floor(p.expires - now),
      },
    };
  });
}

// NOTE: cookiePromotion is sent to override the promotions because the
// promotion that is saved in cookies has its first_seen & last_seen fields set
function setPromotionsLastSeenToNow(
  promotions: Promotion[],
  now: number,
  cookiePromotion?: Promotion
) {
  return promotions.map((promotion) => ({
    ...promotion,
    ...cookiePromotion,
    last_seen: now,
  }));
}

function setPromotionsEndAtIfNotSet(promotions: Promotion[], now: number) {
  return promotions.map((promotion) => ({
    ...promotion,
    end_at: promotion.end_at || Math.floor(promotion.expires - now),
  }));
}

function isPromotionValidForTrip(
  promotion: Promotion,
  trip?: Trip | BasicTrip | RecentDiscoveriesTrip
) {
  const isValid = trip ? new TripValidator(promotion, trip).validate() : true;
  return isValid;
}

export function validatePromotions(
  promotions: Promotion[],
  trip?: Trip | BasicTrip | RecentDiscoveriesTrip
) {
  const validatedPromotinos = promotions.filter((promotion) =>
    new ExpiryValidator(promotion).validate()
  );
  const transformedPromotions = new ConditionsTransformer(validatedPromotinos)
    .transform()
    .filter((promotion) => isPromotionValidForTrip(promotion, trip));
  return transformedPromotions;
}

export function mergePromotions(firstSet: Promotion[], secondSet: Promotion[]) {
  return firstSet && firstSet.length && secondSet && secondSet.length
    ? [...firstSet, ...secondSet]
    : firstSet && firstSet.length
      ? firstSet
      : secondSet && secondSet.length
        ? secondSet
        : [];
}

async function getAutoPromotions(currency: TCurrency): Promise<Promotion[]> {
  const promos = await fetchAutoPromotions();
  const now = Date.now() / 1000;

  const filteredPromotions = filterExpiredPromotions(promos, now, currency);
  const timestampedPromotions = addTimestampsToPromotions(
    filteredPromotions,
    now
  );
  const validPromotions = validatePromotions(timestampedPromotions);
  if (
    validPromotions &&
    validPromotions.length > 0 &&
    validPromotions[0] &&
    validPromotions[0].status &&
    ((validPromotions[0].amount && validPromotions[0].amount[currency]) ||
      validPromotions[0].amount === null)
  ) {
    return [validPromotions[0]];
  }
  return [];
}

function countdownExpired(promo: Promotion) {
  const countDownDate = new Date(
    promo.first_seen ? promo.first_seen * 1000 : 0
  );
  countDownDate.setTime(countDownDate.getTime() + promo.end_at * 1000);
  const endTime = countDownDate.getTime();
  const currentTime = new Date().getTime();
  return currentTime > endTime;
}

async function checkAndValidateCookiePromotions(
  currency: TCurrency,
  cookies: any,
  removeCookie: Function
) {
  if (!cookies.promo || !cookies.promo.code) return [];
  const promo = cookies.promo;
  if (countdownExpired(promo)) {
    removeCookie('promo');
    return [];
  }
  const fetchedPromotion = await fetchPromotionByCode(promo.code);
  const now = getCurrentTimeInSeconds({ isUTC: true });
  const fetchedPromotionWithLastSeenUpdated = setPromotionsLastSeenToNow(
    fetchedPromotion,
    now,
    promo
  );
  const validPromotions = validatePromotions(
    fetchedPromotionWithLastSeenUpdated
  );
  const validPromotionsWithEndAtSet = setPromotionsEndAtIfNotSet(
    validPromotions,
    now
  );

  if (
    validPromotionsWithEndAtSet &&
    validPromotionsWithEndAtSet.length > 0 &&
    validPromotionsWithEndAtSet[0] &&
    validPromotionsWithEndAtSet[0].status &&
    ((validPromotionsWithEndAtSet[0].amount &&
      validPromotionsWithEndAtSet[0].amount[currency]) ||
      validPromotionsWithEndAtSet[0].amount === null)
  ) {
    return [validPromotionsWithEndAtSet[0]];
  }
  return [];
}

function savePromotionToCookies(setCookie: Function, promotion: Promotion) {
  if (!promotion) return;
  const now = getCurrentTimeInSeconds({ isUTC: true });
  const promotionWithLastSeenUpdated = setPromotionsLastSeenToNow(
    [promotion],
    now
  )[0];
  if (
    promotionWithLastSeenUpdated.end_at &&
    promotionWithLastSeenUpdated.first_seen &&
    promotionWithLastSeenUpdated.auto == false // make sure it's not auto
  ) {
    const expiry =
      promotionWithLastSeenUpdated.first_seen +
      promotionWithLastSeenUpdated.end_at;
    setCookie('promo', JSON.stringify(promotionWithLastSeenUpdated), {
      path: '/',
      expires: new Date(expiry * 1000),
    });
  }
}

async function getQueryParameterPromotion(
  currency: TCurrency,
  setCookie: Function,
  queryStringPromo?: string
) {
  if (!queryStringPromo) return [];
  const promo = await fetchPromotionByCode(queryStringPromo);
  const now = getCurrentTimeInSeconds({ isUTC: true });
  const fetchedPromotionsWithTimestamps = addTimestampsToPromotions(promo, now);
  const validPromotions = validatePromotions(fetchedPromotionsWithTimestamps);

  if (
    validPromotions &&
    validPromotions.length > 0 &&
    validPromotions[0] &&
    validPromotions[0].status &&
    ((validPromotions[0].amount && validPromotions[0].amount[currency]) ||
      validPromotions[0].amount === null)
  ) {
    savePromotionToCookies(setCookie, validPromotions[0]);
    return [validPromotions[0]];
  }
  return [];
}

async function getCookiePromotions(
  currency: TCurrency,
  cookies: any,
  setCookie: Function,
  removeCookie: Function,
  queryStringPromo?: string
) {
  const cookiePromotions = await checkAndValidateCookiePromotions(
    currency,
    cookies,
    removeCookie
  );
  const queryParameterPromotion = await getQueryParameterPromotion(
    currency,
    setCookie,
    queryStringPromo
  );
  // if both cookie and param exist and cookie diff from param, set param
  if (
    cookiePromotions &&
    cookiePromotions.length > 0 &&
    queryParameterPromotion &&
    queryParameterPromotion.length > 0
  ) {
    if (cookiePromotions[0].code !== queryParameterPromotion[0].code) {
      savePromotionToCookies(setCookie, queryParameterPromotion[0]);
      return queryParameterPromotion;
    }
  }
  // if cookie exsts, just return it
  if (cookiePromotions && cookiePromotions.length > 0) {
    return cookiePromotions;
  }
  // if param exists, save and return it
  if (queryParameterPromotion && queryParameterPromotion.length > 0) {
    savePromotionToCookies(setCookie, queryParameterPromotion[0]);
    return queryParameterPromotion;
  }
  return [];
}

export async function getAutoAndCookiePromotions(
  currency: TCurrency,
  cookies: any,
  setCookie: Function,
  removeCookie: Function,
  queryStringPromo?: string
): Promise<Promotion[]> {
  const autoPromotions = await getAutoPromotions(currency);
  const cookiePromotions = await getCookiePromotions(
    currency,
    cookies,
    setCookie,
    removeCookie,
    queryStringPromo
  );
  return mergePromotions(cookiePromotions, autoPromotions);
}
