import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

declare var Intercom: any;

function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, ms);
  });
}

const useSetupIntercom = () => {
  const router = useRouter();
  const [hasIntercomInitialized, setHasIntercomInitialized] = useState(false);

  useEffect(() => {
    if (!hasIntercomInitialized) return;

    const contactQueryParam = router.query.contact;
    if (contactQueryParam === 'chat') {
      try {
        if (Intercom && typeof Intercom !== 'undefined') {
          Intercom('show');
        }
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, hasIntercomInitialized]);

  useEffect(() => {
    initIntercom();
  }, []);

  async function initIntercom() {
    if (window === undefined) return;

    let hasError = true;

    for (let count = 0; count < 5; count++) {
      await wait(1000);

      try {
        const id = Intercom('getVisitorId');
        if (id) {
          hasError = false;
          setHasIntercomInitialized(true);
        }
      } catch (e) {
        hasError = true;
      }
      if (!hasError) {
        break;
      }
    }

    if (hasError) {
      setHasIntercomInitialized(true);
    }
  }
};

export { useSetupIntercom };
