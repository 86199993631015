import { GImage } from '../../g-image';
import { AppLink } from '../../link/link';
import dayjs from 'dayjs';
import { FooterCurrency } from './currency';
import { useLocation } from '@/Lib/hooks/useLocation';

const FooterBottom = () => {
  const currentYear = dayjs().year();
  const { location } = useLocation();
  const logos =
    location.continent === 'Americas'
      ? ['consumer-protection-bc']
      : location.continent === 'Europe'
      ? ['atol', 'abta']
      : [];

  return (
    <div className="md:flex md:flex-row py-5 md:px-5 text-dark-800 w-full center items-center justify-between max-w-g-content-width mx-auto text-sm leading-5">
      <FooterCurrency />
      <hr className="md:hidden border-t border-light-800 m-5" />
      <div className="flex justify-center flex-col items-center md:flex-row gap-1">
        <span>Copyright © {currentYear} The Global Work & Travel Co.</span>
        <span>All rights reserved.</span>
      </div>
      <div className="md:flex-1 md:flex md:justify-end">
        {logos.length !== 0 ? (
          <AppLink href="/financial-security">
            <div className="flex h-10 mt-5 md:mt-0 justify-center">
              {logos.map((logo, index) => (
                <div className="h-full mx-2.5" key={index}>
                  <GImage
                    width="auto"
                    height="100%"
                    path={`website/icons/footer/${logo}.svg`}
                    alt={`${logo} logo`}
                    classes="!object-contain"
                  />
                </div>
              ))}
            </div>
          </AppLink>
        ) : null}
      </div>
    </div>
  );
};

export { FooterBottom };
