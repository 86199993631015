import { FC, useRef } from 'react';
import { GImage, generateUrl } from '../g-image';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { AppLink } from '@/Components/link/link';
import { CSSTransition } from 'react-transition-group';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import { redirects } from '@/Lib/configs/redirects';
import { useNotification } from './useNotification';
import Head from 'next/head';

const getRedirectedLink = (link: string): string => {
  let redirectedLink = link;

  redirects.forEach((redirect) => {
    if (redirect.source === link) {
      return (redirectedLink = redirect.destination);
    }
  });

  return redirectedLink;
};

const SocialProof: FC = () => {
  const { isShown, data, neverShowAgain } = useNotification();
  const nodeRef = useRef(null);

  return data ? (
    <CSSTransition
      nodeRef={nodeRef}
      in={isShown}
      timeout={300}
      classNames="popup"
      unmountOnExit
      appear
    >
      <div
        ref={nodeRef}
        className="fixed rounded-md overflow-hidden min-w-21.06rem max-w-21.25rem max-h-7.5rem top-2.5 left-1/2 -translate-x-1/2 md:translate-x-0 md:left-8 md:bottom-8 md:top-auto z-400 bg-light-600"
        style={{
          boxShadow:
            '0 0 1px rgb(9 30 66 / 31%), 0 20px 32px -8px rgb(9 30 66 / 25%)',
        }}
      >
        <Head>
          <link
            rel="prefetch"
            href={generateUrl('website/icons/page/cross.svg')}
            as="image"
          />
        </Head>
        <div className="flex items-center gap-3 p-3 md:p-4">
          <div className="block min-w-1.75 md:min-w-3rem md:h-full ml-2 md:ml-0 h-7">
            <GImage
              alt="social proof icon"
              width="100%"
              height="100%"
              classes="!object-contain"
              path="website/icons/booked.svg"
            />
          </div>
          <AppLink
            href={
              data.url
                ? getRedirectedLink(data.url)
                : getInternalName(data.division)
            }
            className="flex-grow"
          >
            <div className="">
              <p className="text-sm leading-4">
                <span className="capitalize">{data.name}</span>{' '}
                {data.country && data.country.trim().toLowerCase() !== 'n/a' ? (
                  <span>from {data.country}</span>
                ) : null}{' '}
                booked a{' '}
                <b>
                  {data.division} trip{' '}
                  {data.destination ? (
                    <span>
                      to <span className="capitalize">{data.destination}</span>
                    </span>
                  ) : null}
                </b>
              </p>
            </div>
          </AppLink>
          <div
            className="min-w-1.8rem min-h-1.8rem md:min-w-2.3rem md:min-h-2.3rem text-center cursor-pointer"
            onClick={() => {
              neverShowAgain();
            }}
          >
            <div className="w-7 h-7 md:w-9 md:h-9">
              <GImage
                path="website/icons/page/cross.svg"
                alt="cross close icon"
                disableLoadAnimation
              />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  ) : (
    <div></div>
  );
};

export { SocialProof };
