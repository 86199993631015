import { useCookies } from 'react-cookie';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import {
  flagsAreLoadingAtom,
  userAtom,
  userLoginStatusAtom,
} from '../global/global.store';
import { CheckSessionCookie } from '@/Lib/helpers/auth0';
import { useFlags } from 'flagsmith/react';

const useSetUserLoginStatus = () => {
  const [cookies] = useCookies(['auth']);
  const setLoginStatus = useSetAtom(userLoginStatusAtom);
  const setUser = useSetAtom(userAtom);
  const flagsAreLoading = useAtomValue(flagsAreLoadingAtom);
  const flags = useFlags(['FPDC']);
  const fpdcFlag = flags?.FPDC?.enabled;
  useEffect(() => {
    // this bypasses FPDC if opened in gApp

    if (flagsAreLoading) {
      if (fpdcFlag) {
        CheckSessionCookie(cookies?.auth?.accessToken)
          .then((res: any) => {
            setLoginStatus(true);
            setUser({
              email: res.email,
              firstName: res.given_name,
              lastName: res.family_name,
            });
          })
          .catch(() => {
            setLoginStatus(false);
          });
        if (typeof window !== 'undefined') {
          if (window.navigator.userAgent.includes('gApp')) {
            setLoginStatus(true);
            return;
          }
        }
      } else {
        setLoginStatus(true);
      }
    } else {
      setLoginStatus(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies['auth'], flagsAreLoadingAtom, fpdcFlag]);
};

export { useSetUserLoginStatus };
