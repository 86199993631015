import { GImage } from '../g-image';
import { AppLink } from '../link/link';

const FooterBrands = () => {
  return (
    <div className="bg-light-700 mt-5 py-5 text-center">
      <div className="text-base leading-6 font-bold text-dark-800">
        The Global Work & Travel Group
      </div>
      <div className="text-sm font-normal leading-5 pt-2 px-3 pb-0.5 text-dark-700">
        Proudly part of the Global Work & Travel family of brands, a worldwide
        leader in life-changing travel & technology.
      </div>
      <div className="flex justify-center items-end">
        <div className="box-border w-12 lg:w-20 mt-2 mx-2">
          <GImage
            path="website/logo/global-work-and-travel-logo.svg"
            alt="global work and travel logo"
            height="auto"
            width="100%"
            classes="!object-contain"
          />
        </div>
        <div className="w-20 lg:w-32 mt-2 mx-2">
          <a href="http://dealsaway.com/" target="_blank" rel="noreferrer">
            <GImage
              path="website/logo/dealsaway-logo.svg"
              alt="dealsaway logo"
              height="auto"
              width="100%"
              classes="!object-contain"
            />
          </a>
        </div>
        <div className="w-12 lg:w-20 mt-2 mx-2">
          <a
            href="https://globaltravelcover.com"
            target="_blank"
            rel="noreferrer"
          >
            <GImage
              path="new-website/static/homepage/global-travel-cover/gtc-logo.svg"
              alt="global travel cover logo"
              height="auto"
              width="100%"
              classes="!object-contain"
            />
          </a>
        </div>
        <div className="w-12 lg:w-20 mt-2 mx-2">
          <AppLink href="/global-animal-welfare-fund">
            <GImage
              path="website/logo/global-animal-welfare-fund.png"
              alt="global animal welfare fund logo"
              height="auto"
              width="100%"
              classes="!object-contain"
            />
          </AppLink>
        </div>
        <div className="w-5 lg:w-8 mt-2 mx-2">
          <a
            href="https://rescuepawsthailand.org"
            target="_blank"
            rel="noreferrer"
          >
            <GImage
              path="website/logo/rescue-paws-transparent.png"
              alt="rescue paws logo"
              height="auto"
              width="100%"
              classes="!object-contain"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export { FooterBrands };
