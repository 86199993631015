import { MouseEventHandler } from 'react';

type Props = {
  title: string;
  titleCssClasses: string;
  isCloseButtonHidden: boolean;
  mobileTitleCss?: string;
  close: Function;
};

const ModalHeader = ({
  isCloseButtonHidden,
  title,
  titleCssClasses,
  mobileTitleCss,
  close,
}: Props) => {
  return (
    <>
      <div className="py-5 px-4 flex sm:hidden justify-between">
        {title && (
          <h1
            className={
              'flex justify-center items-center text-xl md:text-2xl text-dark-900 font-bold ' +
              mobileTitleCss
            }
          >
            {title}
          </h1>
        )}
        {!isCloseButtonHidden && (
          <i
            className="icon-close text-sm font-bold leading-5"
            onClick={close as MouseEventHandler<HTMLElement>}
          ></i>
        )}
      </div>
      <hr className="sm:hidden border-t border-light-800" />

      {title && (
        <div
          className={
            'hidden sm:flex justify-between items-center ' + titleCssClasses
          }
        >
          <h3
            data-testid="title-desktop"
            className="text-2xl md:text-2.5xl md:leading-6 font-semibold md:font-bold md:flex-grow md:text-center leading-1.875rem text-dark-800"
          >
            {title}
          </h3>
          {!isCloseButtonHidden && (
            <div
              onClick={close as MouseEventHandler<HTMLElement>}
              className="cursor-pointer"
            >
              <i
                className="icon-close text-base font-medium leading-5"
                onClick={close as MouseEventHandler<HTMLElement>}
              ></i>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { ModalHeader };
