import { Provider } from 'jotai';
import { ReactNode } from 'react';
import { SetterProvider } from './global.setter.provider';
import { globalStateStore } from './global.store';

type Props = {
  children: ReactNode;
};

const GlobalStateProvider = ({ children }: Props) => {
  return (
    <Provider store={globalStateStore}>
      <SetterProvider>{children}</SetterProvider>
    </Provider>
  );
};

export { GlobalStateProvider };
