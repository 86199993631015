import { useEffect, useRef, useState } from 'react';
import { useBookedCustomerData } from './useBookedCustomerData';
import { Notification } from '@/Lib/types/social-proof';

const SHOW_TIME = 5 * 1000;
const WAIT_TIME = 10 * 1000;
const INITIAL_TIME = 10 * 1000;

let currentIndex = 0;

const useNotification = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [data, setData] = useState<Notification | null>(null);
  const userChoseToCloseRef = useRef(false);

  const {
    data: bookedCustomers,
    isLoading,
    refetch,
    isFetching,
    updateSeenAnchor,
  } = useBookedCustomerData();

  const neverShowAgain = () => {
    setIsShown(false);
    userChoseToCloseRef.current = true;
  };

  useEffect(() => {
    // prevent running on server
    if (typeof window === 'undefined') return;
    if (isLoading || isFetching || !bookedCustomers) return;

    const showNext = () => {
      let next = bookedCustomers[currentIndex++];
      if (!next) return;

      updateSeenAnchor(next);

      if (currentIndex === bookedCustomers.length) {
        refetch();
        currentIndex = 0;
      }

      setData(next);
      setIsShown(true);
      setTimeout(() => {
        setIsShown(false);
      }, SHOW_TIME);
    };

    let interval: NodeJS.Timer;

    const timeout = setTimeout(() => {
      showNext();

      interval = setInterval(() => {
        if (!userChoseToCloseRef.current) {
          showNext();
        } else {
          clearInterval(interval);
        }
      }, WAIT_TIME + SHOW_TIME);
    }, INITIAL_TIME);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [isLoading, isFetching, bookedCustomers]);

  return { isShown, data, neverShowAgain };
};

export { useNotification };
