import { FC } from 'react';
import { GenericStructuredData } from './generic';
import { ProductPageStructuredData } from './product-page';
import { TStructuredData } from './type';

type props = {
  trip?: TStructuredData;
};

const StructuredData: FC<props> = ({ trip }) => {
  return trip ? (
    <ProductPageStructuredData trip={trip} />
  ) : (
    <GenericStructuredData />
  );
};

export { StructuredData };
